/* eslint-disable no-unexpected-multiline */
/* eslint-disable new-cap */
import { M } from 'imforbossjob'


export default command => command.cata({
    redirectJobPage: jobId => M
        (context => Promise.resolve().then(() => {
            const lang = context?.getLang()
            window.open(location.origin + `/${lang}/${jobId}`)
        })),

})