import { ModalStyledSmall } from "../../styled";
import Content from "./Content";

const Web = ({ open, onCancel, id, onChange, interview_times = [], onSubmit, loading }) => {
  return (
    <ModalStyledSmall open={open} onCancel={onCancel} footer={null} closable={false} width={400}>
      <Content
        interview_times={interview_times}
        id={id}
        onSubmit={onSubmit}
        loading={loading}
        onChange={onChange}
        onCancel={onCancel}
      />
    </ModalStyledSmall>
  );
};

export default Web;
