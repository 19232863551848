import { isMobile } from "react-device-detect";
import Mobile from "./Mobile";
import Web from "./Web";

const ChangeInterviewReason = ({
  open,
  onCancel,
  onOk,
  interview_reason_list,
  reason,
  optionId,
  loading,
  onChange,
  onChangeInterviewReason,
  job_title,
  company_name,
  title,
  desc
}) => {
  if (isMobile) {
    return (
      <Mobile
        open={open}
        onCancel={onCancel}
        onOk={onOk}
        interview_reason_list={interview_reason_list}
        reason={reason}
        optionId={optionId}
        loading={loading}
        onChange={onChange}
        onChangeInterviewReason={onChangeInterviewReason}
        job_title={job_title}
        company_name={company_name}
        title={title}
        desc={desc}
      />
    );
  }

  return (
    <Web
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      interview_reason_list={interview_reason_list}
      reason={reason}
      optionId={optionId}
      loading={loading}
      onChange={onChange}
      onChangeInterviewReason={onChangeInterviewReason}
      job_title={job_title}
      company_name={company_name}
      title={title}
      desc={desc}
    />
  );
};

export default ChangeInterviewReason;
