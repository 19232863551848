import { ModalStyledLarge } from "../../styled";
import Opinion from "../../Opinion";

const Web = ({
  open,
  onCancel,
  onOk,
  interview_reason_list,
  reason,
  optionId,
  loading,
  onChange,
  onChangeInterviewReason,
  job_title,
  company_name,
  title,
  desc
}) => {
  return (
    <ModalStyledLarge open={open} onOk={onOk} onCancel={onCancel} footer={null} destroyOnClose={true} closable={false} >
      <Opinion
        interview_reason_list={interview_reason_list}
        reason={reason}
        optionId={optionId}
        loading={loading}
        onChange={onChange}
        onChangeInterviewReason={onChangeInterviewReason}
        job_title={job_title}
        company_name={company_name}
        title={title}
        desc={desc}
        onCancel={onCancel}
        onOk={onOk}
      />
    </ModalStyledLarge>
  );
};

export default Web;
