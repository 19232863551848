import styled from "@emotion/styled";
import { Modal } from "antd";
import InterviewDetail from "../../Detail";
import { ModalStyledSmall } from "../../styled";

const Web = ({
  open,
  onCancel,
  handleOpenRejectModal,
  handleOpenCancelModal,
  handleOpenEventStartTime,
  data,
  loading,
  onAccept,
}) => {
  return (
    <ModalStyledSmall open={open} onCancel={onCancel} footer={null} closable={false} width={400}>
      <InterviewDetail
        onCancel={onCancel}
        handleOpenRejectModal={handleOpenRejectModal}
        handleOpenCancelModal={handleOpenCancelModal}
        handleOpenEventStartTime={handleOpenEventStartTime}
        data={data}
        loading={loading}
        onAccept={onAccept}
      />
    </ModalStyledSmall>
  );
};

export default Web;
