import { useState } from "react";
import { assign } from "lodash-es";

const useEventStartTime = (contextRef, setIsMobileShowDetail) => {
  const [open, setOpen] = useState(false);
  const [timeId, setTimeId] = useState();

  const context = {
    acceptMobileInterview() {
      setIsMobileShowDetail(false)
      onOpenEventStartTimeModal();
    }
  };
  contextRef.current = assign(contextRef.current, context);

  const onOpenEventStartTimeModal = () => {
    setOpen(true);
  };

  const onCloseEventStartTimeModal = () => {
    setTimeId(undefined)
    setOpen(false);
  };

  const handleSetTimeId = (id) => {
    setTimeId(id);
  };

  return {
    open,
    timeId,

    handleSetTimeId,
    onOpenEventStartTimeModal,
    onCloseEventStartTimeModal
  };
};

export default useEventStartTime;
