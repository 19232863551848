import axios from '../../helpers/axios'


export const sendResume = (applicationId, requestResumeId, params,messageId) => {
    return axios().put(
        `/job-applications/${applicationId}/jobseekers/resume-requests/${requestResumeId}/complete`,
        {
            ...params,
            message_id:messageId
        }
    )
}
export const sendResumeDetail = (applicationId, requestResumeId) => {
    return axios().get(
        `/job-applications/${applicationId}/jobseekers/resume-requests/${requestResumeId}`
    )
}

export const askSendResume = (applicationId, params) => {
    return axios().post(
        `/job-applications/${applicationId}/jobseekers/resume-requests/create`,
        params
    )
}

export const decline = (applicationId, requestResumeId,message_id) => {
    return axios().put(`/job-applications/${applicationId}/jobseekers/resume-requests/${requestResumeId}/decline`,{
        message_id
    })
}

export const getList = () => {
    return axios().get(`/jobseekers/resumes`)
}
export const deleteOne = id => {
    return axios().delete(`/jobseekers/resumes/${id}/delete`)
}