import { Global } from "@emotion/react";
import { DrawerStyled, GlobalStyles } from "../../styled";
import Opinion from "../../Opinion";

const Mobile = ({
  open,
  onCancel,
  onOk,
  interview_reason_list,
  reason,
  optionId,
  loading,
  onChange,
  onChangeInterviewReason,
  job_title,
  company_name,
  title,
  desc
}) => {
  return (
    <>
      <Global styles={GlobalStyles} />
      <DrawerStyled
        placement="bottom"
        onClose={onCancel}
        open={open}
        title={null}
        height={"auto"}
        destroyOnClose={true}
      >
        <Opinion
          interview_reason_list={interview_reason_list}
          reason={reason}
          optionId={optionId}
          loading={loading}
          onChange={onChange}
          onChangeInterviewReason={onChangeInterviewReason}
          job_title={job_title}
          company_name={company_name}
          title={title}
          desc={desc}
          onCancel={onCancel}
          onOk={onOk}
        />
      </DrawerStyled>
    </>
  );
};

export default Mobile;
