import Modal from '../Modal'
import  { useRef, useState,useMemo } from 'react'
import { assign } from 'lodash-es'
import styles from './index.module.scss'
import { getValueById } from '../../helpers/config/getValueById'
const ViewJobModal = (props: any) => {
    const [show, setShow] = useState(false)
    const { contextRef,lang,config} = props
    const actionsRef = useRef({} as any)
    const [job, setJob] = useState(null)

    const {
        JobType,
        yearsOfExp,
        quealitification,
        workLocation,
        address,
        JobFunction,
        skills,
        salary,
        done,
        Monthly,
        Daily,
        Hourly,
        Annually,
        Unpaid,
        remote
    } = lang ?? {};
    const context = {
        showJobDetail(actions) {
            actionsRef.current = actions
            setJob(actions.data)
            setShow(true)
        },
        closeJobDetail() {
            setShow(false)
        }
    }
    const translateCountries = (countries, config) => {
        const country_list = config?.country_lists || []
        const result = countries.map((item) => {
          return country_list.find((country) => country.id === item.id)?.value
        })
        return result
      }
    contextRef.current = assign(contextRef.current, context)
    // const job = contextRef.current.getState?.()?.job
    const {salary_type,local_salary_range_value,local_hourly_salary_range_value,local_daily_salary_range_value, local_annually_salary_range_value, is_un_paid} = job || {}
    const salaryTypeId = salary_type?.id
    const details = useMemo(() => {
        return [
            [JobType, getValueById(config, job?.job_type_id, 'job_type_id')],
            [yearsOfExp,  getValueById(config, job?.xp_lvl?.id, 'xp_lvl_id')],
            [quealitification, getValueById(config, job?.degree?.id, 'degree_id')],
            [workLocation,  job?.remote_countries?.length > 0 ?  translateCountries(job.remote_countries, config).join(',')  :getValueById(config, job?.location?.id, 'location_id')],
            [address, job?.full_address],
            [JobFunction, getValueById(config,job?.function_job_title_id,'function_job_title_id')],
            [skills,
                <div key="skills" className={styles.skillContainer}>
                    {(job?.skills ?? []).map(skill => {
                        return (
                            <div className={styles.skill} key={skill.value}>
                                {skill.value}
                            </div>
                        )
                    })}
                </div>
            ],
            [salary,   !is_un_paid ? (salaryTypeId == 1 ? local_salary_range_value + ` [${Monthly}] `  : salaryTypeId == 2 ? local_daily_salary_range_value + ` [${Daily}] ` : salaryTypeId === 4 ? local_annually_salary_range_value + `[${Annually}]` : local_hourly_salary_range_value + ` [${Hourly}] `) : Unpaid]
        ]
    }, [job])
    return <Modal
        showModal={show}
        handleModal={() => actionsRef.current.close?.()}
        headerTitle={job?.job_title}
        secondButtonText={done}
        secondButtonIsClose={false}
        handleSecondButton={() => actionsRef.current.close?.()}
    >
        <div className={styles.formContainer}>
            {details.map(([label, content], index) => {
                return (
                    <div className={styles.detailItemContainer} key={label+'~'+index}>
                        <label>{label}</label>
                        {typeof content === 'string' ? <p>{content}</p> : content}
                    </div>
                )
            })}
        </div>
    </Modal>
}

export default ViewJobModal