import { Button, Radio, Space, Input } from "antd";
import { CloseSvg, WarningSvg } from "../Svg";
import { OpinionStyled } from "../styled";
import { isMobile } from "react-device-detect";
import { useSharedData } from 'bossjob-remote/dist/hooks'

const { TextArea } = Input;

const Opinion = ({
  interview_reason_list,
  reason,
  optionId,
  loading,
  onChange,
  onChangeInterviewReason,
  job_title,
  company_name,
  title,
  desc,
  onCancel,
  onOk
}) => {
  const disabled = optionId === 3 ? !reason : false;
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { cancel, confirm, cancelTextArea } = dictionary?.interview_v2 || {}

  return (
    <OpinionStyled isMobile={isMobile}>
      {
        isMobile && <div className="mobile_close">
          <CloseSvg onClick={onCancel} />
        </div>
      }

      <div className="title">
        <WarningSvg />
        {title}
      </div>
      <div className="desc">
        <p>
          {desc} {!isMobile && ":"}
        </p>
        {!isMobile && (
          <p className="information">
            {job_title}, {company_name}
          </p>
        )}
      </div>

      <Radio.Group onChange={onChange} value={optionId}>
        {interview_reason_list.map((item) => (
          <Radio key={item.id} value={item.id}>
            {item.reason}
          </Radio>
        ))}
      </Radio.Group>

      {optionId === 3 && (
        <div className="text-area-wrapper" >
          <TextArea
            showCount
            maxLength={200}
            onChange={onChangeInterviewReason}
            value={reason}
            placeholder={cancelTextArea}
            autoSize={true}
          />
        </div>
      )}

      <div className="footer">
        {!isMobile ? (
          <Space size={13}>
            {!isMobile && <Button onClick={onCancel}>{cancel}</Button>}
            <Button type="primary" onClick={onOk} loading={loading} disabled={disabled}>
              {confirm}
            </Button>
          </Space>
        ) : (
          <Button type="primary" onClick={onOk} loading={loading} disabled={disabled} className="mobile_confirm">
            {confirm}
          </Button>
        )}
      </div>
    </OpinionStyled>
  );
};

export default Opinion;
