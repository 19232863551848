import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { assign } from "lodash-es";
import InterviewDetailModal from "../interview-new/Modals/InterviewDetail";
import { detail, check } from "../../interpreters/services/interview";

const Interview = (props) => {
  const { contextRef } = props;
  const actionsRef = useRef({} as any);
  const initData = contextRef.current?.getState()?.interview || "";

  const { id: current_interviewId = null, job_application_id: current_applicationId = null } = initData;

  const [data, setData] = useState<{ [key: string]: any }>({});
  const [show, setShow] = useState(false);

  const isStatusIn = useCallback(
    (status) => {
      return status.includes(data?.jobseeker_display_status);
    },
    [data?.jobseeker_display_status]
  );

  useEffect(() => {
    if (initData?.id) {
      setData(initData);
    }
  }, [initData]);

  const context = {
    showDetail(actions) {
      if (actions) {
        actionsRef.current = actions;
      }
      setShow(true);
    },
    closeDetail() {
      setShow(false);
    },
    canAskResult() {
      return moment().isAfter(moment(data?.interviewed_at).add(30, "minutes")) || isStatusIn(["Completed"]);
    },
    showDetailNew(applicationId, interviewId) {
      if (interviewId === current_interviewId) {
        setShow(true);
        setData(initData);
        return Promise.resolve();
      }

      if (interviewId !== current_interviewId) {
        return handleGetInterviewDetail(applicationId, interviewId);
      }
    },
    interviewCheck(open) {
      if (open) {
        setData(initData);
        setShow(true);
      }

      check(current_applicationId, current_interviewId)
        .then(() => {
          //
        })
        .catch((error) => {
          console.error("check interview error:", error);
        });
    }
  };
  contextRef.current = assign(contextRef.current, context);

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleOpenModal = () => {
    setShow(true);
  };

  const handleGetInterviewDetail = async (applicationId, inviteInterviewId) => {
    return await detail(applicationId, inviteInterviewId)
      .then((response) => {
        const {
          status,
          data: { data }
        } = response;
        if (status === 200) {
          setData(data);
          setShow(true);
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        console.error("Accept interview error:", error);
        return Promise.reject(error);
      });
  };

  return (
    <>
      <InterviewDetailModal
        contextRef={contextRef}
        data={data}
        open={show}
        onOpen={handleOpenModal}
        onCancel={handleCloseModal}
      />
    </>
  );
};

export default Interview;
