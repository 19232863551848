import styled from "@emotion/styled";
import { AboveSvg, CloneSvg, CloseSvg, PackageSvg, PersonSvg, PlaceSvg, WatchSvg } from "../Svg";
import MethodForOnline from "./Online";
import moment from "moment";
import copyToClipboard from "helpers/copyClipboard";
import Footer from "./Footer";
import VisibleResult from "./VisibleResult";
import { useMemo } from "react";
import { useSharedData } from 'bossjob-remote/dist/hooks'
import useInterviewStatusList from "../hooks/useInterviewStatusList";
import { isMobile } from "react-device-detect";
import { getJobSalary, getJobLocation } from "../config";

const InterviewDetail = ({
  onCancel,
  handleOpenRejectModal,
  handleOpenCancelModal,
  handleOpenEventStartTime,
  data,
  loading,
  onAccept,
}) => {
  const configStore = useSharedData('CONFIG') ?? {}
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { interview_status_list } = useInterviewStatusList()

  const {
    job = {},
    interview_type = {},
    address = null,
    interview_times = [],
    contact_person,
    contact_person_contact_num,
    instruction,
    video_category,
    video_link,
    interview_online_method = {},
    status_id,
    recruiter = {},
    interview_result_status_id
  } = data ?? {};
  const { job_title, company = {} } = job;
  const { interview_v2 = {} } = dictionary || {}
  const { modal_inThreeHours } = interview_v2

  const {
    value: statusValue,
    color: statusColor,
    shadow: statusShadow
  } = interview_status_list.find((item) => item.id === status_id) ?? {};
  const { name, logo } = company;
  const { full_name, avatar } = recruiter;

  const repeatJobTitle = useMemo(() => {
    const { job_title } = job || {}

    const location = getJobLocation(job, interview_v2, configStore) ?? null
    const salary = getJobSalary(job, interview_v2) ?? null
    const label = [job_title, location, salary].join(',')
    return label
  }, [job, interview_v2, configStore])

  const handleOnAccept = () => {
    if (interview_times?.length > 1) {
      handleOpenEventStartTime();
      return;
    }

    onAccept();
  };

  const getInterviewTime = () => {
    const confirmedTimes = interview_times.filter((item) => item.is_confirmed);
    return confirmedTimes.length > 0 ? confirmedTimes : interview_times;
  };

  const inThreeHours = useMemo(() => {
    if (status_id === interview_status_list[1].id) {
      const someTime = interview_times.find(item => item.is_confirmed)
      if (someTime && someTime.id && someTime.interviewed_at) {
        const currentTime = moment()
        const interviewAt = moment(someTime.interviewed_at)

        // 如果面试时间已经过去，返回 true
        if (interviewAt.isBefore(currentTime)) {
          return true
        }

        // 面试时间在当前时间之后且小于三小时
        if (interviewAt.isAfter(currentTime) && interviewAt.diff(currentTime, 'minutes') < 180) {
          return true
        } else {
          return false
        }
      }
    }

    return false
  }, [interview_times, status_id, interview_status_list])

  return (
    <DetailCardMainStyled isMobile={isMobile} >
      <div className="close" onClick={onCancel} >
        <CloseSvg />
      </div>

      <div className="wrapper" >
        <div className="header">
          <div className="logo">
            <img src={logo} alt="company_logo" />
          </div>
          <div className="company_name">{name}</div>

          <div className="info">
            <div className="avatar">
              <img src={avatar} alt="avatar" />
            </div>
            <div className="name">
              {full_name}
              <span> · HR</span>
            </div>
          </div>
        </div>

        <div className="fields">
          <div className="field default">
            <div className="left" >
              <PackageSvg width="16px" height="16px" />
            </div>
            <div>{repeatJobTitle}</div>
          </div>

          {interview_type?.id === 1 && (
            <div className="field default">
              <div className="left" >
                <PlaceSvg width="16px" height="16px" />
              </div>
              <div>{address}</div>
            </div>
          )}

          {interview_type?.id === 2 && (
            <MethodForOnline
              video_category={video_category}
              video_link={video_link}
              online_method_id={interview_online_method?.id}
              status_id={status_id}
            />
          )}

          <div className="field time">
            <div className="left" >
              <WatchSvg width="16px" height="16px" />
            </div>
            <div>
              {getInterviewTime().map((item) => (
                <div key={item.id}>{moment(item.interviewed_at).format("MMM DD, YYYY HH:mm")}</div>
              ))}
            </div>
          </div>

          <div className="field">
            <div className="left leftLarge">
              <PersonSvg width="16px" height="16px" />

              <div>
                {contact_person} ( {contact_person_contact_num} )
              </div>
            </div>

            <div className="copy" onClick={() => copyToClipboard(contact_person_contact_num)}>
              <CloneSvg />
            </div>
          </div>

          {instruction && (
            <div className="field comment">
              <div className="left" >
                <AboveSvg width="16px" height="16px" />
              </div>
              <div>{instruction}</div>
            </div>
          )}
        </div>

        {inThreeHours && (
          <div className="in_three_hours_warning">
            <p>{modal_inThreeHours}</p>
          </div>
        )}

        {interview_result_status_id && status_id === 5 && <VisibleResult statusId={interview_result_status_id} />}

        <Footer
          status_id={status_id}
          value={statusValue}
          color={statusColor}
          shadow={statusShadow}
          handleOpenRejectModal={handleOpenRejectModal}
          handleOpenCancelModal={handleOpenCancelModal}
          onAccept={handleOnAccept}
          loading={loading}
          inThreeHours={inThreeHours}
        />
      </div>
    </DetailCardMainStyled>
  );
};

export default InterviewDetail;

type styledProps = {
  interview?: boolean;
  bgColor?: string;
  shadow?: string;
  isMobile?: boolean
};

const DetailCardMainStyled = styled.div<styledProps>`
  width: 100%;
  border-radius: 8px;
  max-height: 80vh;
  background: ${(props) =>
    props.interview ? `linear-gradient(153deg, ${props.bgColor} 1.63%, #FFF 27.18%, #FFF 77.32%)` : "#fff"};

  .close {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
    gap: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .wrapper {
    max-height: calc(80vh - 80px);
    overflow-y: scroll;
    margin-top: 24px;
  }

  .header {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #f6f6f6;

    .logo {
      margin-bottom: 12px;

      img {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #e8e8ea;
        background: #fff;
      }
    }

    .company_name {
      color: #121212;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 4px;
    }

    .info {
      display: flex;
      gap: 4px;
      align-items: center;
      .avatar {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        border: 1.2px solid #e8e8ea;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        color: #121212;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #f6f6f6;

    .field {
      display: flex;
      gap: 16px;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &.default {
        justify-content: flex-start;
      }

      div {
        color: #121212;
        font-size: ${props => props.isMobile ? "16px" : "14px"};
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        word-break: break-all; 
        overflow-wrap: break-word; 
        white-space: normal;
      }

      .left {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        /* flex-grow: 0;
        flex-shrink: 0; */

        svg {
          margin-top: 2px;
          flex-grow: 0;
        flex-shrink: 0;
        }
      }

      .leftLarge {
        /* width: calc(100% - 32px); */
      }

      &.method {
        /* align-items: center; */

        .left {
          align-items: flex-start;

          .link {
            color: #b8b8b8;
            font-feature-settings: "liga" off, "clig" off;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .name {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 4px;
        }

        .enter-interview {
          display: flex;
          padding: 6px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid #1c60b7;
          color: #1c60b7;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 23px;
          margin-top: 4px;
        }
      }

      svg {
        display: block;
      }

      &.time {
        justify-content: flex-start;
      }

      &.comment {
        align-items: start;
        justify-content: flex-start;

        svg {
          margin-top: 4px;
        }
      }
    }
  }

  .copy {
    cursor: pointer;

    :hover {
      path {
        fill: #7d7d7d
      }
    }
  }

  .in_three_hours_warning {
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 2px;
    background: #fff7ed;
    color: #ff9500;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;

    p {
      margin: 0;
    }
  }
`;
