import axios from '../../helpers/axios'

// eslint-disable-next-line no-undef
export const list = () => {
  return axios().get('/jobseekers/common-phrases')
}

export const update = (id, params) => {
  return axios().patch(`/jobseekers/common-phrases/${id}/update`, params)
}

export const deleteOne = (id, params) => {
  return axios().delete(`/jobseekers/common-phrases/${id}/delete`, params)
}

export const create = (params) => {
  return axios().post('/jobseekers/common-phrases/create', params)
}

