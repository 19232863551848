import axios from '../../helpers/axios'
// eslint-disable-next-line no-undef
export const requestFirstService = chatId => {
  return axios().put(`/job-applications/chats/${chatId}/jobseekers/process`)
}

export const updateList = chatList => {
  return  axios()
    .post('job-applications/chats/jobseekers/recruiter-profiles',  { chat_ids: chatList.join(',') })
    .then(res => {
      return res.data
    })
}

