import { RadioChangeEvent } from "antd";
import { useState } from "react";
import { decline } from "../../../interpreters/services/interview";
import { useSharedData } from 'bossjob-remote/dist/hooks'

const useRejectInterview = (id, job_application_id, onOpen, contextRef) => {
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { reject_title, reject_describe, reject_reason_1, reject_reason_2, reject_reason_3 } = dictionary?.interview_v2 || {}

  const title = reject_title;
  const desc = reject_describe;
  const [loading, setLoading] = useState(false);
  const [optionId, setOptionId] = useState(1);
  const [reason, setReason] = useState<null | string>(null);
  const [interviewOpen, setInterviewOpen] = useState(false);

  const interview_reason_list = [
    { id: 1, reason: reject_reason_1 },
    { id: 2, reason: reject_reason_2 },
    { id: 3, reason: reject_reason_3 }
  ];

  const onChangeInterviewReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value as string);
  };

  const interviewOnSubmit = () => {
    const reason_list = [{ id: 3, reason: reason }, ...interview_reason_list];
    const { reason: renameReason } = reason_list.find((item) => item.id === optionId) ?? {};

    setLoading(true);
    decline(job_application_id, id, {
      cancelled_reason: renameReason
    })
      .then(({ status, data }) => {
        if (status === 200) {
          // onOpen();
          interviewOnCancel();
          if (contextRef.current && contextRef.current.updatePath) {
            contextRef.current.updatePath("interview", data);
          }
        }
      })
      .catch((error) => {
        console.error("Reject interview error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const interviewOnCancel = () => {
    setOptionId(1);
    setReason(null);
    setInterviewOpen(false);
  };

  const interviewOnOpen = () => {
    setInterviewOpen(true);
  };

  const onChangeOption = (e: RadioChangeEvent) => {
    setOptionId(e.target.value);
  };

  return {
    interviewOpen,
    interview_reason_list,
    loading,
    onChangeInterviewReason,
    interviewOnSubmit,
    interviewOnCancel,
    interviewOnOpen,

    reason,
    optionId,
    onChangeOption,

    title,
    desc
  };
};

export default useRejectInterview;
