import axios from "../../helpers/axios"

export const accept = (applicationId, offerId) => {
    return axios().put(
        `/job-applications/${applicationId}/jobseekers/offers/${offerId}/accepted`
    )
}

export const decline = (applicationId, offerId) => {
    return axios().put(
        `/job-applications/${applicationId}/jobseekers/offers/${offerId}/declined`
    )
}
export const detail = (applicationId, offerId) => {
    return axios().get(
        `/job-applications/${applicationId}/jobseekers/offers/${offerId}`
    )
}
