'use client'

import React, { useRef, useState, useEffect } from 'react'
import { Popover } from 'antd'
import { Popup, Ellipsis, ImageViewer } from 'antd-mobile'

import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Autoplay, Controller, Pagination, Scrollbar } from "swiper"
import BrandPopover from './brandPopover'
import { fetchBrandProductListByJob } from 'store/services/companyBrand/index'
import useWindowSize from 'hooks/useWindowSize'
import { useSharedData } from 'bossjob-remote/dist/hooks'

import 'swiper/swiper.min.css'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

const BrandSwiper = (props) => {
  const [brandProduct, setBrandProduct] = useState(props.brandProduct || [])
  const [curBrandProduct, setCurBrandProduct] = useState<any>({})
  const [curViewerImg, setCurViewerImg] = useState<any>({})
  const [showPrevArrow, setShowPrevArrow] = useState(false)
  const [showNextArrow, setShowNextArrow] = useState(false)
  const [showPopupType, setShowPopupType] = useState('')
  const langDic = useSharedData('DICTIONARY') || {}
  const companyDetailLangDic = langDic?.companyDetail || {}
  const previewImgs = curViewerImg?.pictures || []
  const previewIndex = curViewerImg?.index || 0

  const swiperRef = useRef(null)
  const { width } = useWindowSize()
  const isMobile = width < 767
  const pageType = props.pageType

  useEffect(() => {
    const { jobId } = props;
    const getBrandProductListByJob = async () => {
      try {
        const { data } = await fetchBrandProductListByJob(jobId)
        const brandProduct = data?.data?.data || []
        setBrandProduct(brandProduct)
        if (brandProduct.length > 1) {
          setShowNextArrow(true)
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (jobId) {
      getBrandProductListByJob() // 调用获取品牌产品列表的函数
    }

    return () => { }
  }, [props.jobId])

  const handleShowPopupType = (popType, data = null) => {
    if (data !== null) {
      setCurBrandProduct(data);
    }
    setTimeout(() => {
      setShowPopupType(popType)
    }, 500)
  }

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setShowPrevArrow(swiperRef.current.swiper.isBeginning === false);
      setShowNextArrow(swiperRef.current.swiper.isEnd === false);
    }
  }

  const CustomPrevArrow = () => (
    <span
      className="swiper-button-prev"
      onClick={() => swiperRef.current.swiper.slidePrev()}
    >
      <PreIcon />
    </span>
  )

  const CustomNextArrow = () => (
    <span
      className="swiper-button-next"
      onClick={() => swiperRef.current.swiper.slideNext()}
    >
      <NextIcon />
    </span>
  )

  if (!brandProduct?.length) {
    return null
  }
  const { itemWidth } = props

  return (
    <>
      <Global styles={GlobalStyles} />
      <BrandSwiperWrap>
        {isMobile && pageType == 'job-detail' && <div className='page-line'></div>}
        <div className={!isMobile && pageType === 'job-detail' ? 'brand-swiper-wrap' : 'brand-swiper-wrap-1'}>
          <p className='block-title'>{props.title || companyDetailLangDic?.overview?.BrandorProduct}</p>
          <div className='swiper-wrap'>
            <Swiper
              modules={[Pagination, Scrollbar, A11y, Autoplay, Controller]}
              ref={swiperRef}
              spaceBetween={16}
              slidesPerView={!isMobile ? 1.35 : 1.6}
              grabCursor={true}
              // autoplay={{
              // 	delay: 10 * 1000,
              // 	disableOnInteraction: true
              // }}
              loop={false}
              scrollbar={{ draggable: true }}
              className='brand-swiper'
              onSlideChange={handleSlideChange}
            >
              {brandProduct.map((item, index) => (
                <SwiperSlide key={index}>
                  <Popover placement="left" trigger={'hover'} overlayClassName="custom-popover" content={!isMobile && <BrandPopover data={item} />}>
                    <div className='item' style={itemWidth ? { width: itemWidth } : undefined} onClick={() => isMobile && handleShowPopupType('brand-popup', item)}>
                      <img className='logo' src={item.logo_url} alt="img" />
                      <div className='item-right'>
                        <div className='brand-name'>{item.name || item.title}</div>
                        <div className='brand-intro'>{item.description}</div>
                      </div>
                    </div>
                  </Popover>
                </SwiperSlide>
              ))}
            </Swiper>
            {showPrevArrow && !isMobile ? <CustomPrevArrow /> : null}
            {showNextArrow && !isMobile && brandProduct.length ? <CustomNextArrow /> : null}
          </div>
        </div>
        <Popup
          visible={showPopupType === 'brand-popup'}
          className='brand-popup'
          bodyStyle={{ maxHeight: '80vh', borderRadius: '16px 16px 0px 0px', overflowY: 'auto' }}
        >
          <div style={{ padding: '16px', paddingBottom: '24px' }}>
            {
              showPopupType === 'brand-popup' ? (
                <>
                  <div className='pop-top'>
                    <span className="close-icon" onClick={() => { handleShowPopupType('') }}><CloseIcon /></span>
                    <div><img className='logo' src={curBrandProduct.logo_url} alt="img" /></div>
                    <p className='brand-name'>{curBrandProduct.name || curBrandProduct.title}</p>
                    <p className='brand-slogan'>{curBrandProduct.slogan || curBrandProduct.sub_title}</p>
                  </div>
                  <div className='brand-intro'>
                    <Ellipsis rows={3} content={curBrandProduct.description} expandText={<span className='more'>{companyDetailLangDic?.overview?.More}</span>} collapseText={<span className='less'>{companyDetailLangDic?.overview?.Less}</span>}></Ellipsis>
                  </div>
                  {
                    curBrandProduct?.pictures?.length ? (
                      <div className='related-pictures'>
                        {curBrandProduct.pictures.map((item, idx) => {
                          let url = typeof item === 'string' ? item : '';

                          return (
                            <img
                              key={url}
                              src={url}
                              onClick={() => { setCurViewerImg({ pictures: curBrandProduct.pictures, index: idx }) }}
                            />
                          )
                        })}
                      </div>
                    ) : null
                  }
                </>
              ) : null
            }
          </div>
        </Popup>
        { // 多图显示
          previewImgs?.length ?
            <ImageViewer.Multi
              images={previewImgs?.map(item => item.url) || []}
              visible={previewImgs}
              defaultIndex={previewIndex}
              classNames={{ mask: 'custom-img-viewer-mask', body: pageType == 'job-detail' ? 'custom-img-viewer-body-job-detail' : 'custom-img-viewer-body' }}
              maxZoom="auto"
              onClose={() => {
                setCurViewerImg({})
              }}
            />
            // 单图显示
            // <ImageViewer
            //   classNames={{
            //     mask: 'custom-img-viewer-mask',
            //     body: 'custom-img-viewer-body',
            //   }}
            //   image={previewImgs[previewIndex].url}
            //   visible={previewImgs}
            //   onClose={() => {
            //     setCurViewerImg({})
            //   }}
            // />
            : null
        }
      </BrandSwiperWrap>
    </>
  );
};

export default BrandSwiper;

const GlobalStyles = css`
 html,
 body {
  .custom-popover {
    .ant-popover-arrow {
      //display: none;
    }
  }
  .brand-popup {
    .more, .less {
      color: #2378E5;
      cursor: pointer;
    }
    .pop-top {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 6px;
      flex-direction: column;
      align-items: center;
      .logo {
        margin-top: 8px;
        width: 50px;
        height: 50px;
        border-radius: 8px;
      }
      .brand-name {
        color: #121212;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
      }
      .brand-slogan {
        color: #7D7D7D;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .brand-intro {
      margin-top: 16px;
      color: #515151;
      font-size: 14px;
      line-height: normal;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .related-pictures {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 12px;
      img {
        width: calc(33.333% - 8px);
        height: 80px;
        object-fit: cover; 
      }
    }
    .close-icon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }
  .custom-img-viewer-mask {
    z-index: 2000 !important;
  }
  .custom-img-viewer-body {
    img {
      border-radius: 0;
      border: none;
    }
  }
  .custom-img-viewer-body-job-detail {
    .adm-image-viewer-slides-inner > * {
      margin-right: 0;
    }
  }
}
`

const BrandSwiperWrap = styled.div`
  border-radius: 8px;
  background: #FFFFFF;
  .page-line {
    width: 100%;
    height: 1px;
    padding: 0 16px;
    background: #ffffff;

    &::after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid #E4E4E4;
    }
  }
  
  .brand-swiper-wrap {
    padding: 28px;
    margin-bottom: 16px;
  }

  .brand-swiper-wrap-1 {
    padding: 20px 20px 0;
  }

  .block-title {
    color: #121212;
    font-size: 20px;
    font-weight: 700;
  }

  .swiper-wrap {
    position: relative;
    width: 100%;
    margin-top: 14px;
  }

  .item {
    position: relative;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #E5E6EB;
    background: #FFFFFF;
    &:hover {
      .item-popup {
        display: block;
      }
    }
    .logo {
      width: 48px;
      height: 48px;
      border-radius: 8px;
    }
    .item-right {
      width: calc(100% - 64px); /* 设置宽度 */
      flex: 1;
      .brand-name {
        line-height: 1;
        color: #121212;
        font-size: 14px;
        white-space: nowrap; /* 防止换行 */
        overflow: hidden; /* 溢出隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
      }
      .brand-intro {
        margin-top: 6px;
        line-height: 1;
        color: #7D7D7D;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }

  .item-popup {
    display: none;
    position: absolute;
    width: 502px;
    right: 222px;
    z-index: 999999;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
  }

  .swiper-button-prev {
    left: -26px;
  }

  .swiper-button-next {
    right: -26px;
  }
`

const PreIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="white" />
    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#F6F6F6" />
    <path d="M17 20L12 15.0172L17 10" stroke="#515151" stroke-width="1.5" stroke-linecap="round" />
  </svg>

}

const NextIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="white" />
    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#F6F6F6" />
    <path d="M13 20L18 15.0172L13 10" stroke="#515151" stroke-width="1.5" stroke-linecap="round" />
  </svg>
}

const CloseIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M6.5 6.5L17.5 17.5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M6.5 17.5L17.5 6.5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>