import configuredAxios from 'helpers/configuredAxios'
import { cache } from 'react'
const fetchJobsForYouLogin = (payload) => {
  const { jobseekerPrefId } = payload || {}
  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  delete payload?.jobseekerPrefId
  return axios.get(`/reco`, { params: { ...payload, job_preference_id: jobseekerPrefId } })
}

const fetchJobsForYouDelete = (payload) => {
  const { job_preference_id, job_id, ...rest } = payload || {}
  const axios = configuredAxios('job', false)
  return axios.delete(`/job-preferences/${job_preference_id}/reco-jobs/${job_id}/delete`, { params: rest })

}

const fetchJobsPreferences = cache(() => {
  const endpointType = 'protected'
  const axios = configuredAxios('jobseeker', endpointType, false)
  return axios.get(`/job-preferences`)

})

const fetchSearchJobRecommendations = (payload) => {
  const { jobseekerPrefId } = payload || {}
  const endpointType = 'public'
  const axios = configuredAxios('recommendation', endpointType, false)
  delete payload?.jobseekerPrefId
  return axios.get(`/search-job-recommendations`, { params: { ...payload } })
}

const fetchJobsForYouLoginRemote = (payload) => {
  const { jobseekerPrefId } = payload || {}
  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  delete payload?.jobseekerPrefId
  return axios.get(`/reco-remote-jobs`, { params: { ...payload, job_preference_id: jobseekerPrefId } })
}

export {
  fetchJobsForYouLogin,
  fetchJobsPreferences,
  fetchJobsForYouDelete,
  fetchJobsForYouLoginRemote,
  fetchSearchJobRecommendations
}