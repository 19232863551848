import { useEffect, useRef, useState, } from 'react'
import { assign } from 'lodash-es'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { Modal, Button } from 'antd'
import { isMobile, } from 'react-device-detect'

import styles from './index.module.scss'
import { BossjobIcon } from './DownIcon'
import axios from '../../helpers/axios'


export const DownloadModal = (props: any) => {
  const { contextRef, loading, applicationId, userId, lang } = props
  const [open, setOpen] = useState(false)
  const dic = useSharedData('DICTIONARY') ?? {}
  const { comm = {}, downLoadModalInChat = {}, header = {} } = dic || {}

  const [state, setState] = useState({
    initialed: false,
    canOpen: true,
    opened: false,
    wantToOpen: false
  })
  const stateRef = useRef(state);
  stateRef.current = state

  const hideDownloadModal = (flag) => {
    setOpen(false)
    if (Boolean(flag)) {
      console.log('post')
      axios().post('jobseekers/popup-window/record', {
        "device_type": isMobile ? "mobile_web" : 'web',
        "popup_window_type": "download_app",
        "popup_window_position": "chat"
      })
    }
    // Boolean(flag) && localStorage.setItem(downKey, 'true')
  }

  useEffect(() => {
    console.log('down load app __debugger')
    axios().get('jobseekers/popup-window/record/list').then(res => {
      console.log('res', res.data.data)
      let data = res.data?.data || []
      let canOpen = false;

      let openRecord = data.filter(v => { return v.popup_window_type === 'download_app' && v.popup_window_position === 'chat' })
      canOpen = !openRecord.length;

      setState(pre => {
        return { ...pre, initialed: true, canOpen }

      })
    }).catch(err => {
      console.error(err)
    })
  }, [])

  useEffect(() => {
    if (state.wantToOpen && state.initialed && state.canOpen && !state.opened) {
      setState(pre => {
        return { ...pre, opened: true }
      })
      setOpen(true)
    }
  }, [state])

  const context = {
    showDownloadModal() {
      setState(pre => {
        return { ...pre, wantToOpen: true }
      })
      // user closed download manually, so, we should display twice
    },
    hideDownloadModal
  }

  contextRef.current = assign(contextRef.current, context)

  if (isMobile) {
    return <Modal
      width={302}
      open={open}
      onCancel={hideDownloadModal}
      footer={null}
      zIndex={1000 * 10}
      closable={false}
      rootClassName={styles.downModalForMobile}
    >
      <div className={styles.bgCover} />
      <div style={{ textAlign: 'center' }}>
        <div className={styles.bossjobIcon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="46" height="43" viewBox="0 0 46 43" fill="none">
            <path d="M3.89746 19.1739C3.89746 20.6759 4.99802 21.7873 6.51473 21.7873C6.86073 21.7957 7.20484 21.7339 7.52619 21.6055C7.84754 21.4772 8.13942 21.285 8.38416 21.0406C8.62889 20.7962 8.82137 20.5048 8.94992 20.1839C9.07847 19.863 9.14041 19.5194 9.13199 19.1739C9.13199 17.6056 8.00386 16.5605 6.51473 16.5605C4.99802 16.5605 3.89746 17.6056 3.89746 19.1739Z" fill="#FED766" />
            <path d="M20.5482 14.5694C23.6819 14.5694 25.5721 12.2688 25.5721 9.59284C25.5721 6.91683 23.6794 4.6501 20.5482 4.6501C17.417 4.6501 15.5593 6.91558 15.5593 9.59284C15.5593 12.2701 17.4521 14.5694 20.5482 14.5694ZM20.5482 12.3377C19.0001 12.3377 18.1403 11.0685 18.1403 9.59284C18.1403 8.11715 19.0001 6.84674 20.5482 6.84674C22.1313 6.84674 22.9912 8.11715 22.9912 9.59284C22.9912 11.0685 22.1313 12.3364 20.5482 12.3364V12.3377ZM31.4957 14.5694C34.1105 14.5694 35.6247 13.2989 35.6247 11.5492C35.6247 7.73916 29.984 8.90569 29.984 7.53389C29.984 7.01821 30.5682 6.60642 31.4631 6.60642C32.6326 6.60642 33.7006 7.1221 34.2847 7.70536L35.2875 5.95306C34.1965 5.09852 32.8472 4.63913 31.4606 4.6501C28.9537 4.6501 27.5748 6.02691 27.5748 7.63652C27.5748 11.3426 33.1842 10.0735 33.1842 11.6167C33.1842 12.2013 32.6677 12.6181 31.601 12.6181C30.4315 12.6181 29.0201 11.9659 28.332 11.3138L27.2627 13.0874C28.2956 14.0199 29.8787 14.5694 31.4957 14.5694ZM41.4956 14.5694C44.1104 14.5694 45.6246 13.2989 45.6246 11.5492C45.6246 7.73916 39.9839 8.90569 39.9839 7.53389C39.9839 7.01821 40.5693 6.60642 41.4643 6.60642C42.6338 6.60642 43.7005 7.1221 44.2846 7.70536L45.2874 5.95306C44.1985 5.09986 42.8522 4.64014 41.4681 4.64885C38.9548 4.64885 37.5823 6.02566 37.5823 7.63527C37.5823 11.3414 43.1903 10.0722 43.1903 11.6155C43.1903 12.2 42.6739 12.6168 41.6072 12.6168C40.4377 12.6168 39.0263 11.9647 38.3381 11.3126L37.2639 13.0874C38.2617 14.0199 39.8436 14.5694 41.4956 14.5694Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.38574 0.375061H6.90505V4.71079H8.71381C9.36355 4.71079 10.0069 4.83858 10.6072 5.08686C11.2075 5.33513 11.7529 5.69904 12.2123 6.1578C12.6718 6.61656 13.0362 7.16119 13.2849 7.76059C13.5335 8.35998 13.6615 9.00242 13.6615 9.6512C13.6615 10.9615 13.1402 12.2181 12.2123 13.1446C11.2845 14.0711 10.026 14.5916 8.71381 14.5916H4.38574V0.375061ZM6.90428 11.6403V12.4061H8.34235L8.34158 12.4046C8.70929 12.4047 9.07341 12.3325 9.41314 12.192C9.75288 12.0515 10.0616 11.8456 10.3216 11.586C10.5816 11.3263 10.7878 11.0181 10.9285 10.6789C11.0692 10.3396 11.1415 9.97604 11.1414 9.60888C11.1414 8.8674 10.8464 8.1563 10.3214 7.632C9.79629 7.10771 9.08414 6.81316 8.34158 6.81316H6.90505V11.6403H6.90428Z" fill="white" />
            <path d="M3.24051 42.5001C1.92983 42.5001 1.04576 42.2854 0.375 42.0399L1.16783 38.6979C1.61837 38.8567 2.09209 38.9396 2.56975 38.9434C4.03205 38.9434 4.45995 37.9627 4.45995 36.0321V23.6681H8.94068V35.9138C8.94068 39.5913 7.53748 42.5001 3.24051 42.5001Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3928 40.2819C24.7357 40.2819 27.9649 36.3513 27.9649 31.7766C27.9649 27.202 24.7332 23.3291 19.3928 23.3291C14.0524 23.3291 10.8772 27.2045 10.8772 31.7766C10.8772 36.3487 14.1064 40.2819 19.3928 40.2819ZM19.3928 36.4682C16.7496 36.4682 15.2808 34.2985 15.2808 31.7753C15.2808 29.2521 16.7483 27.0824 19.3928 27.0824C22.0912 27.0824 23.5612 29.2534 23.5612 31.7753C23.5612 34.2972 22.0912 36.4682 19.3928 36.4682Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M29.909 16.3751H34.1096V23.7418H37.1254C38.2088 23.7418 39.2815 23.9553 40.2824 24.37C41.2833 24.7847 42.1927 25.3925 42.9588 26.1588C43.7248 26.9251 44.3325 27.8348 44.747 28.836C45.1616 29.8372 45.375 30.9103 45.375 31.9939C45.375 34.1825 44.5058 36.2815 42.9588 37.829C41.4117 39.3766 39.3133 40.246 37.1254 40.246H29.909V16.3751ZM34.1083 35.3166V36.5956H36.5061L36.5048 36.593C37.1179 36.5932 37.725 36.4725 38.2915 36.2379C38.8579 36.0033 39.3726 35.6593 39.8062 35.2256C40.2397 34.792 40.5835 34.2771 40.8181 33.7105C41.0526 33.1438 41.1733 32.5365 41.1731 31.9232C41.1731 30.6847 40.6813 29.497 39.8058 28.6212C38.9303 27.7455 37.7429 27.2535 36.5048 27.2535H34.1096V35.3166H34.1083Z" fill="white" />
          </svg>
        </div>
      </div>
      <div className={styles.bossjobAppText}>
        {/* Bossjob APP */}
        {comm.bossjobApp || 'Bossjob APP'}
      </div>
      <div style={{ margin: '40px 0 16px 0' }}>
        <div className={styles.header}>
          {downLoadModalInChat.header || 'Don’t miss any new opportunities!'}
        </div>
        <div className={styles.desc}>
          {downLoadModalInChat.description || "Download the Bossjob APP now and communicate with your boss online about work"}
        </div>
      </div>

      <div>
        <Button
          style={{ width: '100%', height: 48 }}
          type='primary'
          onClick={() => {
            window.open('https://cyyj.adj.st/bossjob.ph/home?adj_t=1eiwqkhp', '_blank')
            hideDownloadModal(true)
          }}
        >
          <span style={{ fontSize: 16 }}>
            {header.downloadApp || "Download APP"}
          </span>
        </Button>
        <div className={styles.laterBtn} onClick={hideDownloadModal}>
          {comm.Later || "Later"}
        </div>
      </div>
    </Modal >
  }

  return <Modal
    open={open}
    // onCancel={hideDownloadModal}
    footer={null}
    closable={false}
    maskClosable={false}
    zIndex={1000 * 10}
    rootClassName={styles.downModal}
  >
    <div className={styles.closeIconRoot}>
      <span className={styles.closeIconWrapper}
        onClick={() => { hideDownloadModal(true) }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M18.3641 7.05025C18.7546 6.65972 18.7546 6.02656 18.3641 5.63603C17.9736 5.24551 17.3404 5.24551 16.9499 5.63603L12.0001 10.5858L7.05037 5.63603C6.65984 5.24551 6.02668 5.24551 5.63616 5.63603C5.24563 6.02656 5.24563 6.65972 5.63616 7.05025L10.5859 12L5.63616 16.9497C5.24563 17.3403 5.24563 17.9734 5.63616 18.364C6.02668 18.7545 6.65984 18.7545 7.05037 18.364L12.0001 13.4142L16.9499 18.364C17.3404 18.7545 17.9736 18.7545 18.3641 18.364C18.7546 17.9734 18.7546 17.3403 18.3641 16.9497L13.4143 12L18.3641 7.05025Z" fill="#7D7D7D" />
        </svg>
      </span>
    </div>

    <div>
      <div className={styles.header}>
        {/* Don’t miss any new opportunities! */}
        {downLoadModalInChat.header}
      </div>
      <div className={styles.desc}>
        {/* Download the Bossjob APP now and communicate with your boss online about work */}
        {downLoadModalInChat.description}
      </div>
      <div style={{ textAlign: 'center', margin: '24px 0' }}>
        <BossjobIcon />
      </div>
    </div>
    <div style={{ textAlign: 'center' }}>
      <Button
        // style={{ height: 42, minWidth: 166 }}
        className={styles.gotItBtn}
        onClick={hideDownloadModal}
      >
        {/* Got it~ */}
        {comm.gotIt}
      </Button>
    </div>
  </Modal >
}
