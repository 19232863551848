import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import { FormHelperText } from '@mui/material'
import classNames from 'classnames'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const MaterialBasicSelect = ({
  id,
  label,
  options,
  className,
  onSelect,
  onOpen,
  greyBg,
  defaultValue,
  fieldRef,
  disabled,
  required,
  error,
  useID = false,
  hiddenLabel = false,
  onChange,
  ...rest
}: any) => {


  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            transform: 'translate(14px, 10px) scale(1)',
            letterSpacing: '1px',
            '&.Mui-focused': {
              fontSize: '10px',
              transform: 'translate(14px, -10px) scale(1)'
            },
            lineHeight: '26px'
          },
          shrink: {
            fontSize: '10px',
            transform: 'translate(14px, -10px) scale(1)'
          },
          outlined: {
            '&.MuiInputLabel-shrink': {
              fontSize: '10px'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            height: '44px',
            backgroundColor: greyBg ? '#E2E2E2' : 'white',
            lineHeight: '16px',
            alignItems: 'self-end'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: '14px',
            letterSpacing: '1px'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            letterSpacing: '1px',
            padding: '10px 16px'
          }
        }
      }
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <FormControl className={classNames({
        [className]: true,
        // [styles.hiddenLabel]: hiddenLabel
      })} size='small' error={!!error}>
        {/* <InputLabel id={`${id}-select-label`} 
          // className={'testInput'}
        >
          {
            <span>
              {label} {required ? <span style={{ color: 'red' }}>{' *'}</span> : ''}
            </span>
          }
        </InputLabel> */}
        <Autocomplete
          id={id}
          onChange={(event, newValue: any) => {
            onChange(newValue?.value)
          }}
          defaultValue={defaultValue}
          disabled={disabled}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(error?.message)} // 显示错误状态
              helperText={error?.message} // 显示错误信息
            />
          )}
          // 你可以在这里添加其他需要的props，例如 onOpen
          {...rest}
        />
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    </ThemeProvider>
  )
}
export default MaterialBasicSelect
