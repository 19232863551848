const ArrowSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2559 6.07739C17.5814 6.40283 17.5814 6.93047 17.2559 7.2559L10.5893 13.9226C10.2638 14.248 9.73618 14.248 9.41074 13.9226L2.74408 7.2559C2.41864 6.93046 2.41864 6.40283 2.74408 6.07739C3.06951 5.75195 3.59715 5.75195 3.92259 6.07739L10 12.1548L16.0774 6.07739C16.4028 5.75195 16.9305 5.75195 17.2559 6.07739Z"
        fill="#515151"
      />
    </svg>
  );
};

const MinArrowSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8047 4.86193C14.0651 5.12228 14.0651 5.54439 13.8047 5.80474L8.4714 11.1381C8.21105 11.3984 7.78894 11.3984 7.5286 11.1381L2.19526 5.80474C1.93491 5.54439 1.93491 5.12228 2.19526 4.86193C2.45561 4.60158 2.87772 4.60158 3.13807 4.86193L8 9.72386L12.8619 4.86193C13.1223 4.60158 13.5444 4.60158 13.8047 4.86193Z"
        fill="#515151"
      />
    </svg>
  );
};

const DetailCardClose = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86353 8L1.7499 2.88636L2.88626 1.75L7.9999 6.86364L13.1135 1.75L14.2499 2.88636L9.13626 8L14.2499 13.1136L13.1135 14.25L7.9999 9.13636L2.88626 14.25L1.7499 13.1136L6.86353 8Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

const CloseSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.36364 8L2.25 2.88636L3.38636 1.75L8.5 6.86364L13.6136 1.75L14.75 2.88636L9.63636 8L14.75 13.1136L13.6136 14.25L8.5 9.13636L3.38636 14.25L2.25 13.1136L7.36364 8Z" fill="#7D7D7D" />
    </svg>
  );
};

const PackageSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M14.1666 3.33334V5.00001H15.8333C17.214 5.00001 18.3333 6.11929 18.3333 7.50001V15.8333C18.3333 17.214 17.214 18.3333 15.8333 18.3333H4.16663C2.78592 18.3333 1.66663 17.2141 1.66663 15.8333V7.5C1.66663 6.11929 2.78591 5.00001 4.16663 5.00001H5.83329V3.33334C5.83329 2.41286 6.57949 1.66667 7.49996 1.66667H12.5C13.4204 1.66667 14.1666 2.41286 14.1666 3.33334ZM7.49996 8.33334H5.83329V6.66667H4.16663C3.70639 6.66667 3.33329 7.03977 3.33329 7.5L3.33329 11.6667H16.6666V7.50001C16.6666 7.03977 16.2935 6.66667 15.8333 6.66667H14.1666V8.33334H12.5V6.66667H7.49996V8.33334ZM16.6666 13.3333H3.33329L3.33329 15.8333C3.33329 16.2936 3.70639 16.6667 4.16663 16.6667H15.8333C16.2935 16.6667 16.6666 16.2936 16.6666 15.8333V13.3333ZM12.5 5.00001V3.33334L7.49996 3.33334V5.00001H12.5Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

const PlaceSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58317 8.50225C4.58317 5.39633 7.0291 2.91667 9.99984 2.91667C12.9706 2.91667 15.4165 5.39633 15.4165 8.50225C15.4165 9.17279 15.1101 10.0522 14.5458 11.0624C13.9928 12.0521 13.2447 13.076 12.4798 14.0113C11.7174 14.9435 10.9527 15.7702 10.3775 16.3648C10.2396 16.5073 10.1128 16.6363 9.99984 16.7498C9.8869 16.6363 9.76012 16.5073 9.62219 16.3648C9.04698 15.7702 8.28231 14.9435 7.5199 14.0113C6.75496 13.076 6.00684 12.0521 5.4539 11.0624C4.88953 10.0522 4.58317 9.17279 4.58317 8.50225ZM9.42912 18.5239C9.42926 18.524 9.42937 18.5241 9.99984 17.9167C10.5703 18.5241 10.5704 18.524 10.5706 18.5239L9.99984 19.0599L9.42912 18.5239ZM9.42912 18.5239L9.99984 17.9167L10.5706 18.5239L10.5722 18.5224L10.5763 18.5185L10.5912 18.5044C10.604 18.4923 10.6226 18.4746 10.6465 18.4516C10.6944 18.4058 10.7638 18.3388 10.8515 18.2528C11.0271 18.0809 11.2765 17.8325 11.5753 17.5236C12.172 16.9069 12.9698 16.0447 13.7699 15.0664C14.5675 14.0913 15.3818 12.9831 16.0008 11.8753C16.6083 10.7879 17.0832 9.60425 17.0832 8.50225C17.0832 4.51803 13.9327 1.25 9.99984 1.25C6.06701 1.25 2.9165 4.51803 2.9165 8.50225C2.9165 9.60425 3.39139 10.7879 3.9989 11.8753C4.61784 12.9831 5.43222 14.0913 6.22977 15.0664C7.02986 16.0447 7.82769 16.9069 8.42436 17.5236C8.72315 17.8325 8.9726 18.0809 9.14813 18.2528C9.23592 18.3388 9.30528 18.4058 9.35316 18.4516L9.37786 18.4753L9.40851 18.5044L9.42339 18.5185L9.42751 18.5224L9.42912 18.5239ZM8.74984 7.91667C8.74984 7.22631 9.30948 6.66667 9.99984 6.66667C10.6902 6.66667 11.2498 7.22631 11.2498 7.91667C11.2498 8.60702 10.6902 9.16667 9.99984 9.16667C9.30948 9.16667 8.74984 8.60702 8.74984 7.91667ZM9.99984 5C8.38901 5 7.08317 6.30584 7.08317 7.91667C7.08317 9.5275 8.38901 10.8333 9.99984 10.8333C11.6107 10.8333 12.9165 9.5275 12.9165 7.91667C12.9165 6.30584 11.6107 5 9.99984 5Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

const WatchSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33329 10C3.33329 6.3181 6.31806 3.33333 9.99996 3.33333C13.6819 3.33333 16.6666 6.3181 16.6666 10C16.6666 13.6819 13.6819 16.6667 9.99996 16.6667C6.31806 16.6667 3.33329 13.6819 3.33329 10ZM9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666ZM9.16662 9.91057L12.0976 13.067L13.3189 11.933L10.8333 9.25609V5.41666H9.16662V9.91057Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

const PersonSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50004 6.24998C7.50004 4.86927 8.61933 3.74998 10 3.74998C11.3808 3.74998 12.5 4.86927 12.5 6.24998C12.5 7.63069 11.3808 8.74998 10 8.74998C8.61933 8.74998 7.50004 7.63069 7.50004 6.24998ZM10 2.08331C7.69885 2.08331 5.83337 3.94879 5.83337 6.24998C5.83337 8.55117 7.69885 10.4166 10 10.4166C12.3012 10.4166 14.1667 8.55117 14.1667 6.24998C14.1667 3.94879 12.3012 2.08331 10 2.08331ZM6.66671 11.25C5.46736 11.25 4.32937 11.6154 3.47664 12.3373C2.61069 13.0703 2.08337 14.1364 2.08337 15.4166V16.6666C2.08337 17.357 2.64302 17.9166 3.33337 17.9166H16.6667C17.3571 17.9166 17.9167 17.357 17.9167 16.6666V15.4166C17.9167 14.1364 17.3894 13.0703 16.5234 12.3373C15.6707 11.6154 14.5327 11.25 13.3334 11.25H6.66671ZM3.75004 15.4166C3.75004 14.6258 4.06219 14.0252 4.55347 13.6094C5.05797 13.1823 5.79499 12.9166 6.66671 12.9166H13.3334C14.2051 12.9166 14.9421 13.1823 15.4466 13.6094C15.9379 14.0252 16.25 14.6258 16.25 15.4166V16.25H3.75004V15.4166Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

const AboveSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08329 2.91667L17.9166 2.91667L17.9166 4.58333L2.08329 4.58333L2.08329 2.91667ZM2.08329 9.16667L14.375 9.16666L14.375 10.8333L2.08329 10.8333L2.08329 9.16667ZM2.08329 15.4167L7.91663 15.4167L7.91663 17.0833L2.08329 17.0833L2.08329 15.4167Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

const VideoSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66659 4.83333V12.1667H10.3333V4.83333H2.66659ZM1.33325 4.5C1.33325 3.94772 1.78097 3.5 2.33325 3.5H10.6666C11.2189 3.5 11.6666 3.94772 11.6666 4.5V5.60398L13.7126 4.99018C14.3542 4.7977 14.9999 5.27815 14.9999 5.94801V11.052C14.9999 11.7219 14.3542 12.2023 13.7126 12.0098L11.6666 11.396V12.5C11.6666 13.0523 11.2189 13.5 10.6666 13.5H2.33325C1.78097 13.5 1.33325 13.0523 1.33325 12.5V4.5ZM11.6666 10.004L13.6666 10.604V6.39602L11.6666 6.99602V10.004ZM5.66659 7.5H3.66659V6.16667H5.66659V7.5Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

const CloneSvg = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 3.16634H6.66667V1.83301H12.6667C13.403 1.83301 14 2.42996 14 3.16634V10.4997H12.6667V3.16634ZM2 5.83301C2 5.09663 2.59695 4.49967 3.33333 4.49967H10C10.735 4.49967 11.3333 5.09415 11.3333 5.83193V13.8358C11.3333 14.5748 10.7347 15.1663 10.0009 15.1663H3.33184C2.59351 15.1663 2 14.5674 2 13.833V5.83301ZM10 5.83301H3.33333V13.833H10V5.83301Z"
        fill="#B8B8B8"
      />
    </svg>
  );
};

const WarningSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337ZM10.8335 5.38769L10.8335 12.0544H9.16683V5.38769L10.8335 5.38769ZM9.16601 15.0003V13.3337H10.8327V15.0003H9.16601Z"
        fill="#FF9500"
      />
    </svg>
  );
};

const TimeSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9993 5.33366C10.1083 5.33366 5.33268 10.1093 5.33268 16.0003C5.33268 21.8914 10.1083 26.667 15.9993 26.667C21.8904 26.667 26.666 21.8914 26.666 16.0003C26.666 10.1093 21.8904 5.33366 15.9993 5.33366ZM2.66602 16.0003C2.66602 8.63653 8.63555 2.66699 15.9993 2.66699C23.3631 2.66699 29.3327 8.63653 29.3327 16.0003C29.3327 23.3641 23.3631 29.3337 15.9993 29.3337C8.63555 29.3337 2.66602 23.3641 2.66602 16.0003ZM14.666 9.33366H17.3327V14.667H22.3327V17.3337H14.666V9.33366Z"
        fill="#FF9500"
      />
    </svg>
  );
};

const SunColorSmilingSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <ellipse cx="13.9845" cy="13.9722" rx="13.9845" ry="13.9722" fill="url(#paint0_linear_11475_22913)" />
      <path
        d="M5.75143 8.31815L9.16985 10.0259V8.91501L5.75143 10.6227C5.4444 10.7761 5.31996 11.1491 5.47347 11.4559C5.62698 11.7626 6.00032 11.887 6.30734 11.7336L9.72576 10.0259C10.1839 9.79702 10.1839 9.14386 9.72576 8.91501L6.30734 7.2073C6.00032 7.05392 5.62698 7.17826 5.47347 7.48501C5.31996 7.79176 5.4444 8.16477 5.75143 8.31815Z"
        fill="black"
      />
      <path
        d="M22.2205 7.2073L18.8021 8.91501C18.344 9.14386 18.344 9.79702 18.8021 10.0259L22.2205 11.7336C22.5275 11.887 22.9009 11.7626 23.0544 11.4559C23.2079 11.1491 23.0834 10.7761 22.7764 10.6227L19.358 8.91501V10.0259L22.7764 8.31815C23.0834 8.16477 23.2079 7.79176 23.0544 7.48501C22.9009 7.17826 22.5275 7.05392 22.2205 7.2073Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8064 14.2832H4.34766C4.88872 19.1734 9.0383 22.977 14.077 22.977C19.1158 22.977 23.2654 19.1734 23.8064 14.2832Z"
        fill="black"
      />
      <mask
        id="mask0_11475_22913"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="4"
        y="14"
        width="20"
        height="9"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8064 14.2832H4.34766C4.88872 19.1734 9.0383 22.977 14.077 22.977C19.1158 22.977 23.2654 19.1734 23.8064 14.2832Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11475_22913)">
        <ellipse cx="14.139" cy="22.8212" rx="11.9645" ry="6.05462" fill="#F55065" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11475_22913"
          x1="-6.41319"
          y1="6.42393"
          x2="6.42734"
          y2="34.3769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDD40" />
          <stop offset="1" stopColor="#FFBF00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SunColorSadSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
      <g filter="url(#filter0_i_11369_22617)">
        <ellipse cx="14.4845" cy="13.9722" rx="13.9845" ry="13.9722" fill="url(#paint0_linear_11369_22617)" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6256 9.00046C23.0078 9.35505 23.2549 9.71169 23.3849 9.97149C23.5384 10.2782 23.414 10.6513 23.107 10.8046C22.8 10.958 22.4266 10.8337 22.2731 10.5269C22.2608 10.5024 22.2249 10.4408 22.1652 10.3547C22.0607 10.2038 21.932 10.0518 21.7798 9.91057C21.2649 9.43293 20.6242 9.19517 19.8093 9.31149C19.4694 9.35999 19.1546 9.12408 19.1061 8.78456C19.0575 8.44505 19.2937 8.1305 19.6335 8.082C20.8458 7.90895 21.8513 8.28206 22.6256 9.00046ZM6.60239 10.527C6.61465 10.5025 6.65059 10.4408 6.71026 10.3547C6.81478 10.2039 6.94352 10.0518 7.09574 9.91062C7.61057 9.43298 8.25128 9.19521 9.06623 9.31153C9.40605 9.36003 9.72087 9.12412 9.76942 8.7846C9.81796 8.44509 9.58184 8.13054 9.24203 8.08204C8.02965 7.90899 7.02423 8.28211 6.24989 9.00051C5.86769 9.3551 5.62058 9.71173 5.49056 9.97154C5.33705 10.2783 5.46149 10.6513 5.76852 10.8047C6.07554 10.9581 6.44888 10.8337 6.60239 10.527ZM10.0848 13.2938C10.0848 14.4608 9.31957 15.407 8.3756 15.407C7.43163 15.407 6.66639 14.4608 6.66639 13.2938C6.66639 12.1267 7.43163 11.1805 8.3756 11.1805C9.31957 11.1805 10.0848 12.1267 10.0848 13.2938ZM22.2061 13.294C22.2061 14.4611 21.4409 15.4072 20.4969 15.4072C19.553 15.4072 18.7877 14.4611 18.7877 13.294C18.7877 12.1269 19.553 11.1808 20.4969 11.1808C21.4409 11.1808 22.2061 12.1269 22.2061 13.294ZM14.5547 17.2852C16.3253 17.2852 17.9291 18.2172 18.8191 19.7174C18.9941 20.0124 18.8966 20.3933 18.6014 20.5682C18.3061 20.7431 17.9248 20.6457 17.7498 20.3506C17.0815 19.2242 15.882 18.5272 14.5547 18.5272C13.2202 18.5272 12.0028 19.2339 11.3346 20.3523C11.1586 20.6468 10.777 20.743 10.4823 20.5672C10.1876 20.3914 10.0913 20.0102 10.2672 19.7157C11.1574 18.2258 12.7777 17.2852 14.5547 17.2852Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_i_11369_22617"
          x="0.5"
          y="-1.64706"
          width="27.9688"
          height="29.5914"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.64706" />
          <feGaussianBlur stdDeviation="2.47059" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.689571 0 0 0 0 0.76894 0 0 0 0 0.927678 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11369_22617" />
        </filter>
        <linearGradient
          id="paint0_linear_11369_22617"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="27.9444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD937" />
          <stop offset="1" stopColor="#FFC206" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SunColorPokerFaceSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <g filter="url(#filter0_i_11369_22628)">
        <ellipse cx="13.9845" cy="13.9722" rx="13.9845" ry="13.9722" fill="url(#paint0_linear_11369_22628)" />
      </g>
      <ellipse cx="7.8762" cy="13.2939" rx="1.70921" ry="2.11321" fill="black" />
      <ellipse cx="19.9973" cy="13.2939" rx="1.70921" ry="2.11321" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.86002 10.2323C5.87821 10.2118 5.92888 10.1616 6.0088 10.0938C6.14879 9.97519 6.3125 9.86167 6.49608 9.76466C7.11699 9.43654 7.7974 9.3727 8.55449 9.69598C8.87017 9.83078 9.23532 9.68439 9.37009 9.36901C9.50485 9.05363 9.35819 8.68869 9.04251 8.55389C7.91623 8.07295 6.8485 8.17313 5.91461 8.66664C5.45366 8.91023 5.12266 9.19075 4.92983 9.40805C4.70215 9.66462 4.72582 10.0571 4.98268 10.2847C5.23954 10.5124 5.63234 10.4889 5.86002 10.2323Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9467 9.40792C22.7539 9.19062 22.4229 8.9101 21.9619 8.66651C21.028 8.173 19.9603 8.07282 18.834 8.55376C18.5183 8.68856 18.3717 9.0535 18.5064 9.36888C18.6412 9.68426 19.0063 9.83066 19.322 9.69586C20.0791 9.37257 20.7595 9.43641 21.3804 9.76453C21.564 9.86154 21.7277 9.97506 21.8677 10.0937C21.9476 10.1614 21.9983 10.2117 22.0165 10.2322C22.2442 10.4888 22.637 10.5122 22.8938 10.2846C23.1507 10.057 23.1744 9.66449 22.9467 9.40792Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4678 20.1621C10.4678 19.7479 10.8036 19.4121 11.2178 19.4121H16.7822C17.1964 19.4121 17.5322 19.7479 17.5322 20.1621C17.5322 20.5763 17.1964 20.9121 16.7822 20.9121H11.2178C10.8036 20.9121 10.4678 20.5763 10.4678 20.1621Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_i_11369_22628"
          x="0"
          y="-1.64706"
          width="27.9688"
          height="29.5914"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.64706" />
          <feGaussianBlur stdDeviation="2.47059" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.689571 0 0 0 0 0.76894 0 0 0 0 0.927678 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_11369_22628" />
        </filter>
        <linearGradient id="paint0_linear_11369_22628" x1="0" y1="0" x2="0" y2="27.9444" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDD83C" />
          <stop offset="1" stopColor="#F8C51E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SuccessSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.0089 7.74023H15.7526C15.4794 7.74023 15.2196 7.87148 15.0588 8.09648L10.8481 13.9358L8.94099 11.2893C8.78028 11.067 8.52314 10.9331 8.24724 10.9331H6.99099C6.81688 10.9331 6.7151 11.1313 6.81688 11.2733L10.1544 15.9018C10.2332 16.0119 10.3372 16.1016 10.4576 16.1634C10.578 16.2253 10.7114 16.2575 10.8468 16.2575C10.9822 16.2575 11.1156 16.2253 11.236 16.1634C11.3564 16.1016 11.4604 16.0119 11.5392 15.9018L17.1803 8.08041C17.2847 7.93845 17.183 7.74023 17.0089 7.74023Z"
        fill="#52C41A"
      />
      <path
        d="M12 0C5.37321 0 0 5.37321 0 12C0 18.6268 5.37321 24 12 24C18.6268 24 24 18.6268 24 12C24 5.37321 18.6268 0 12 0ZM12 21.9643C6.49821 21.9643 2.03571 17.5018 2.03571 12C2.03571 6.49821 6.49821 2.03571 12 2.03571C17.5018 2.03571 21.9643 6.49821 21.9643 12C21.9643 17.5018 17.5018 21.9643 12 21.9643Z"
        fill="#52C41A"
      />
    </svg>
  );
};

export {
  ArrowSvg,
  CloseSvg,
  DetailCardClose,
  PackageSvg,
  MinArrowSvg,
  PlaceSvg,
  WatchSvg,
  PersonSvg,
  AboveSvg,
  VideoSvg,
  CloneSvg,
  WarningSvg,
  TimeSvg,
  SunColorSmilingSvg,
  SunColorSadSvg,
  SunColorPokerFaceSvg,
  SuccessSvg
};
