import { Button } from "antd";
import moment from "moment";
import { CloseSvg, TimeSvg } from "../../Svg";
import { isMobile } from "react-device-detect";
import { useSharedData } from 'bossjob-remote/dist/hooks'

const Content = ({ interview_times, id, onSubmit, loading, onChange, onCancel }) => {
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { time_theEmployerProvides, time_choose, time_confirm, cancel, confirm } = dictionary?.interview_v2 || {}

  return (
    <div className="event_start_times">
      <div className="close">
        <CloseSvg onClick={onCancel} />
      </div>
      <div className="icon">
        <TimeSvg />
      </div>
      <p className="desc">
        {time_theEmployerProvides} {interview_times.length} {time_choose}
      </p>
      <div className="times_wrapper">
        {interview_times.map((item) => (
          <div className={id === item.id ? "time_active" : null} onClick={() => onChange(item.id)} key={item.id}>
            {moment(item.interviewed_at)?.format("MMM DD, YYYY HH:mm")}
          </div>
        ))}
      </div>

      {isMobile && (
        <div className="footer">
          <Button type="primary" disabled={!id} onClick={onSubmit} loading={loading}>
            {time_confirm}
          </Button>
        </div>
      )}

      {!isMobile && (
        <div className="footer-web">
          <Button onClick={onCancel}>{cancel}</Button>
          <Button type="primary" disabled={!id} onClick={onSubmit} loading={loading}>
            {confirm}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Content;
