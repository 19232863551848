/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from 'react'
import * as ReactDOM from 'react-dom'
import classNames from 'classnames'
import Text from '../Text'
import styles from './Modal.module.scss'
import { CloseIcon } from '../../images'

type ModalProps = {
  style?: object
  className?: string
  children: React.ReactNode
  showModal: boolean
  handleModal: any
  closeModalOnOutsideClick?: boolean
  fullScreen?: boolean
  customFooter?: React.ReactNode
  bodyClass?: string
}

export const PureModal = ({
  style,
  className,
  children,
  showModal,
  closeModalOnOutsideClick = true,
  handleModal,
  fullScreen = false,
  bodyClass,
  ...rest
}: ModalProps) => {
  const ref = useRef(null)
  const scrollY = useRef(0)
  const handleCloseModal = () => {
    document.documentElement.classList.remove('modal-active')
    window.scrollTo(0, scrollY.current)
    handleModal(false)
  }

  const handleClickOutside = (event) => {
    if (
      event.target.getAttribute('class') &&
      event.target.getAttribute('class').includes('modalWrapper')
    )
      handleCloseModal()
  }

  const syncHeight = () => {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`)
    window.addEventListener('resize', syncHeight)
    scrollY.current = window.pageYOffset
    if (closeModalOnOutsideClick) document.addEventListener('mousedown', handleClickOutside, true)
    return () => {
      if (closeModalOnOutsideClick) document.removeEventListener('mousedown', handleClickOutside, true)
      window.removeEventListener('resize', syncHeight)
    }
  }, [])
  if (!showModal) return null

  return ReactDOM.createPortal(
    <>
      <div className={styles.modalOverlay} ref={ref} />
      <div
        className={styles.modalWrapper}
        style={style}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role='dialog'
        {...rest}
      >
        <div
          className={classNames([
            fullScreen ? styles.modalContentFullscreen : styles.modalContent,
            className
          ])}
        >
          <div className={classNames([styles.modalCloseButton, styles.pureModalClose])}>
            <Text onClick={handleCloseModal}>
              <img src={CloseIcon} title='close modal' alt='close modal' width='14' height='14' />
            </Text>
          </div>
          <div className={classNames([styles.modalBody, bodyClass])}>{children}</div>
        </div>
      </div>
    </>,
    document.body
  )
}
