import { Global } from "@emotion/react";
import { DrawerStyled, GlobalStyles } from "../../styled";
import Content from "./Content";

const Mobile = ({ open, onCancel, id, onChange, interview_times = [], onSubmit, loading }) => {
  return (
    <>
      <Global styles={GlobalStyles} />
      <DrawerStyled placement="bottom" onClose={onCancel} open={open} title={null} height={"auto"}>
        <Content interview_times={interview_times} id={id} onSubmit={onSubmit} loading={loading} onChange={onChange} onCancel={onCancel} />
      </DrawerStyled>
    </>
  );
};

export default Mobile;
