import { useState, useRef } from 'react'
import { assign } from 'lodash-es'
import styles from './index.module.scss'
import Modal from '../Modal'
const CommonPhrasesDeleteModal = (props: any) => {
    const { contextRef, loading, dic } = props
    const [show, setShow] = useState(false)
    const [phrase, setPhrase] = useState<any>({})
    const actionsRef = useRef<any>()
    const context = {
        showDeleteOneCommonPhrases(actions) {
            actionsRef.current = actions
            setShow(true)
            setPhrase(actions.payload)
        },
        closeDeleteOneCommonPhrases() {
            setShow(false)
        },
    }
    contextRef.current = assign(contextRef.current, context)

    return <Modal
        showModal={show}
        handleModal={() => actionsRef.current?.back?.()}
        headerTitle={dic?.phraseTitle}
        firstButtonText={dic?.back}
        secondButtonText={dic?.delete}
        firstButtonIsClose={false}
        secondButtonIsClose={false}
        handleFirstButton={() => actionsRef.current?.back?.()}
        handleSecondButton={() => actionsRef.current.delete?.({ id: phrase.id, params: { message: phrase.message } })}
        isSecondButtonLoading={loading}
        isFirstButtonLoading={loading}>
        <div className={styles.modalContainer}>
            {/* <p className={styles.modalTitle}>Common Phrases</p> */}
            <div className={styles.formContainer}>
                <p>{dic?.sureDelete}</p>
                <h5>{phrase.message}</h5>
            </div>
        </div>
    </Modal>
}

export default CommonPhrasesDeleteModal
