import axios from '../../helpers/axios'

export const getAuth = userId => {
    ///job-applications
    return axios().get(`/job-applications/chats/jobseekers/im-auth-code?auid=${userId}&ver=1`)
}
export const list = params => {
    return axios().get(`/job-applications/chats/jobseekers/chat-ids`, { params })
}
export const createChat = (jobId, params) => {
    return axios().post(`/jobs/${jobId}/jobseekers/chat`, params)
}
export const check = (recruiter_ids) => {
    return axios().get(`/job-applications/chats/check-exists/jobseekers`, { params: { recruiter_ids } })
}
export const infoAlert = (chatId, hasPhone, hasEmail) => {
    return axios().post('/job-applications/chats/jobseekers/contact-exchange-requests/alert', {
        'send-text-phone': hasPhone ? 'true' : 'false',
        'send-text-email': hasEmail ? 'true' : 'false',
        chat_id: chatId
    })
}
export const infoAlertMessage = (chatId) => {
    return axios().post('/job-applications/chats/jobseekers/message-filter/alert', {
        send_text_link: true,
        chat_id: chatId
    })
}