import { isMobile } from "react-device-detect";
import Mobile from "./Mobile";
import Web from "./Web";

import ChangeInterviewReason from "../Reason";
import useRejectInterview from "../../hooks/useRejectInterview";
import useCancelInterview from "../../hooks/useCancelInterview";
import EventStartTime from "../EventStartTime";
import useEventStartTime from "../../hooks/useEventStartTime";
import useDetailInterview from "../../hooks/useDetailInterview";
import { useState } from "react";

const InterviewDetailModal = ({ data, open, onOpen, onCancel, contextRef }) => {
  const [isMobileShowDetail, setIsMobileShowDetail] = useState(true)

  const { job_title, company_name, id, job_application_id, interview_times } = data;

  const {
    open: eventStartTimeOpen,
    timeId,
    onOpenEventStartTimeModal,
    onCloseEventStartTimeModal,
    handleSetTimeId
  } = useEventStartTime(contextRef, setIsMobileShowDetail);

  const { loading, onAccept } = useDetailInterview(data, contextRef, timeId, onCancel);

  const {
    interviewOpen: rejectInterviewOpen,
    interview_reason_list: interview_reject_reason,
    loading: loadingReject,
    onChangeInterviewReason: onRejectInterviewReason,
    interviewOnSubmit: rejectInterviewOnSubmit,
    interviewOnCancel: rejectInterviewOnCancel,
    interviewOnOpen: rejectInterviewOnOpen,

    reason: rejectReason,
    optionId: rejectOptionId,
    onChangeOption: rejectOnChangeOption,
    title: rejectTitle,
    desc: rejectDesc
  } = useRejectInterview(id, job_application_id, onOpen, contextRef);

  const {
    interviewOpen: cancelInterviewOpen,
    interview_reason_list: interview_cancel_reason,
    loading: loadingCancel,
    onChangeInterviewReason: onCancelInterviewReason,
    interviewOnSubmit: cancelInterviewOnSubmit,
    interviewOnCancel: cancelInterviewOnCancel,
    interviewOnOpen: cancelInterviewOnOpen,

    reason: cancelReason,
    optionId: cancelOptionId,
    onChangeOption: cancelOnChangeOption,
    title: cancelTitle,
    desc: cancelDesc
  } = useCancelInterview(id, job_application_id, onOpen, contextRef);

  // Reject modal
  const handleOpenRejectModal = () => {
    onCancel();
    rejectInterviewOnOpen();
  };

  const handleCloseRejectModal = () => {
    rejectInterviewOnCancel();
    onOpen();
  };

  // Cancel modal
  const handleOpenCancelModal = () => {
    onCancel();
    cancelInterviewOnOpen();
  };

  const handleCloseCancelModal = () => {
    cancelInterviewOnCancel();
    onOpen();
  };

  // Time modal
  const handleOpenEventStartTime = () => {
    onCancel();
    onOpenEventStartTimeModal();
  };

  const handleCloseEventStartTime = () => {
    onCloseEventStartTimeModal();

    if (isMobileShowDetail) {
      onOpen();
    }

    setIsMobileShowDetail(true)
  };

  const handleOnAcceptInterview = () => {
    if (!timeId) return;
    onAccept().then(() => {
      handleCloseEventStartTime();
      onCancel()
    });
  };

  return (
    <>
      {isMobile ? (
        <Mobile
          open={open}
          onCancel={onCancel}
          handleOpenRejectModal={handleOpenRejectModal}
          handleOpenCancelModal={handleOpenCancelModal}
          handleOpenEventStartTime={handleOpenEventStartTime}
          data={data}
          loading={loading}
          onAccept={onAccept}
        />
      ) : (
        <Web
          open={open}
          onCancel={onCancel}
          handleOpenRejectModal={handleOpenRejectModal}
          handleOpenCancelModal={handleOpenCancelModal}
          handleOpenEventStartTime={handleOpenEventStartTime}
          data={data}
          loading={loading}
          onAccept={onAccept}
        />
      )}

      {/* Reject modal */}
      <ChangeInterviewReason
        open={rejectInterviewOpen}
        onCancel={handleCloseRejectModal}
        onOk={rejectInterviewOnSubmit}
        interview_reason_list={interview_reject_reason}
        reason={rejectReason}
        optionId={rejectOptionId}
        loading={loadingReject}
        onChange={rejectOnChangeOption}
        onChangeInterviewReason={onRejectInterviewReason}
        job_title={job_title}
        company_name={company_name}
        title={rejectTitle}
        desc={rejectDesc}
      />

      {/* Cancel modal */}
      <ChangeInterviewReason
        open={cancelInterviewOpen}
        onCancel={handleCloseCancelModal}
        onOk={cancelInterviewOnSubmit}
        interview_reason_list={interview_cancel_reason}
        reason={cancelReason}
        optionId={cancelOptionId}
        loading={loadingCancel}
        onChange={cancelOnChangeOption}
        onChangeInterviewReason={onCancelInterviewReason}
        job_title={job_title}
        company_name={company_name}
        title={cancelTitle}
        desc={cancelDesc}
      />

      <EventStartTime
        open={eventStartTimeOpen}
        onCancel={handleCloseEventStartTime}
        onSubmit={handleOnAcceptInterview}
        id={timeId}
        onChange={handleSetTimeId}
        interview_times={interview_times}
        loading={loading}
      />
    </>
  );
};

export default InterviewDetailModal;
