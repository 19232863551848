import { Global } from "@emotion/react";
import { DrawerStyled, GlobalStyles } from "../../styled";
import InterviewDetail from "../../Detail";

const Mobile = ({
  open,
  onCancel,
  handleOpenRejectModal,
  handleOpenCancelModal,
  handleOpenEventStartTime,
  data,
  loading,
  onAccept
}) => {
  return (
    <>
      <Global styles={GlobalStyles} />
      <DrawerStyled placement="bottom" onClose={onCancel} open={open} title={null} height={"auto"}>
        <InterviewDetail
          onCancel={onCancel}
          handleOpenRejectModal={handleOpenRejectModal}
          handleOpenCancelModal={handleOpenCancelModal}
          handleOpenEventStartTime={handleOpenEventStartTime}
          data={data}
          loading={loading}
          onAccept={onAccept}
        />
      </DrawerStyled>
    </>
  );
};

export default Mobile;
