import copy from "copy-to-clipboard";
import { message } from "antd";

export const copyToClipboard = (text, options = {}) => {
  const { onSuccess, onError } = options;

  try {
    const result = copy(text, {
      debug: options.debug || false, // 可选：启用调试模式
      format: options.format || "text/plain" // 可选：指定格式
    });

    if (result && typeof onSuccess === "function") {
      onSuccess();
    } else {
      message.success("Copy success");
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    } else {
      message.warn("Copy fail");
    }
  }
};

export default copyToClipboard;
