import axios from '../../helpers/axios'

export const accept = (applicationId, exchangeId, messageId) => {
    return axios().put(
        `/job-applications/${applicationId}/jobseekers/contact-exchange-requests/${exchangeId}/approve`,
        {
            message_id: messageId
        }
    )
}

export const decline = (applicationId, exchangeId, messageId, params) => {
    return axios().put(`/job-applications/${applicationId}/jobseekers/contact-exchange-requests/${exchangeId}/decline`,{
        ...params,
        message_id: messageId
    })
}

export const create = (applicationId) => {
    return axios().post(`/job-applications/${applicationId}/jobseekers/contact-exchange-requests/create`)
}

export const sendOTP = (params) => {
    return axios().post('/authentication/sms-otp/change-phone-number/generate',params)
}

export const verify = params => {
    return axios().post('/authentication/change-phone-number',params)
}