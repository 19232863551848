import { isMobile } from "react-device-detect";
import Mobile from "./Mobile";
import Web from "./Web";

const EventStartTime = ({ open, onCancel, id, onChange, interview_times, onSubmit, loading }) => {
  if (isMobile) {
    return (
      <Mobile
        open={open}
        onCancel={onCancel}
        id={id}
        onChange={onChange}
        interview_times={interview_times}
        onSubmit={onSubmit}
        loading={loading}
      />
    );
  }

  return (
    <Web
      open={open}
      onCancel={onCancel}
      id={id}
      onChange={onChange}
      interview_times={interview_times}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export default EventStartTime;
