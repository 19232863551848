import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Drawer, Modal } from "antd";

const GlobalStyles = css`
  .ant-drawer-content-wrapper {
    box-shadow: unset !important;
  }
`;

const DrawerStyled = styled(Drawer)`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  max-height: 80vh;
  min-height: 300px;
  overflow: scroll !important;

  .ant-drawer-header {
    display: none !important;
  }

  .ant-drawer-header-close-only {
    display: none !important;
  }

  .ant-drawer-body {
    padding: 20px 0px;

    .wrapper, .close {
      padding: 0 24px;
    }

    /* EventStartTimeModal */
    .event_start_times {
      padding: 0 24px;

      .close {
        text-align: right;
        padding: 0;
      }

      .icon {
        text-align: center;
        svg {
          display: block;
          margin: 0 auto;
        }
      }
      .desc {
        color: #515151;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 12px 0;
      }

      .times_wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        div {
          text-align: center;
          padding: 20px 0px;
          text-transform: capitalize;
          border-radius: 4px;
          background: #f6f6f6;
          color: #121212;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: 1px solid transparent;

          transition: all 0.1s ease;
        }

        .time_active {
          color: #2378e5;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          border-radius: 4px;
          border: 1px solid #2378e5;
          background: #f5f7fb;
        }
      }

      .footer {
        margin: 28px 0 0;

        .ant-btn.ant-btn-primary {
          display: flex;
          height: 48px;
          padding: 10px 50px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          border-radius: 8px;
          width: 100%;
        }

        .ant-btn-primary[disabled] {
          border: none;
        }
      }
    }
  }
`;

const OpinionStyled = styled.div<any>`
  padding: ${props => props.isMobile ? '0 24px' : 0};

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .mobile_close {
    text-align: right;
    padding: 0;
  }

  .desc {
    margin: 24px 0;
    color: #7d7d7d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .information {
      color: #121212;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .ant-radio-wrapper {
      line-height: 17px;
    }

    label {
      display: block;
    }
  }

  .text-area-wrapper {
    margin-bottom: 48px;
    .ant-input-textarea-affix-wrapper {
      ::after {
        content: ""
      }

      .ant-input-suffix {
        .ant-input-data-count {
          font-size: 12px;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;

    .ant-btn {
      display: flex;
      height: 40px;
      padding: 10px 48px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .ant-btn.mobile_confirm {
      width: 100%;
    }

    .ant-btn.ant-btn-default {
      border: 1px solid #e4e4e4;
      color: #515151;
    }
  }
`;

const ModalStyledLarge = styled(Modal)`
  .ant-modal-content {
    display: flex;
    width: 500px;
    padding: 36px;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
    }

    .ant-modal-body {
      padding: 0;

      .desc {
        p:first-child {
          margin-bottom: 2px;
        }
      }
    }
  }
`;

//
const ModalStyledSmall = styled(Modal)`
  .ant-modal-content {
    .ant-modal-body {
      padding: unset;

      /* EventStartTimeModal */
      .event_start_times {
        .close {
          margin-bottom: 24px;
          display: flex;
          justify-content: end;
          cursor: pointer;

          svg {
            display: block;
          }
        }

        .icon {
          margin-bottom: 12px 0;
          text-align: center;
          svg {
            display: block;
            margin: 0 auto;
          }
        }
        .desc {
          color: #515151;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 12px 0;
          margin-bottom: 24px;
        }

        .times_wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;

          div {
            text-align: center;
            padding: 20px 0px;
            text-transform: capitalize;
            border-radius: 4px;
            background: #f6f6f6;
            color: #121212;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border: 1px solid transparent;
            cursor: pointer;
            transition: all 0.1s ease;
          }

          .time_active {
            color: #2378e5;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            border-radius: 4px;
            border: 1px solid #2378e5;
            background: #f5f7fb;
          }
        }

        .footer {
          margin: 28px 0 0;

          .ant-btn.ant-btn-primary {
            display: flex;
            height: 48px;
            padding: 10px 50px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            width: 100%;
          }
        }

        .footer-web {
          display: flex;
          justify-content: center;
          margin-top: 24px;
          margin-bottom: 12px;
          gap: 16px;

          .ant-btn {
            display: flex;
            height: 36px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
          }
        }
      }
    }
  }
`;

export { ModalStyledSmall, ModalStyledLarge, DrawerStyled, GlobalStyles, OpinionStyled };
