import { useState, useEffect } from 'react'
import { assign } from 'lodash-es'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { Modal, Drawer, message, Button, Radio, Space } from 'antd'
import { reportRecruiter } from '../../interpreters/services/report'
// import { isMobile } from 'react-device-detect'
import styles from './index.module.scss'


const CloseIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M18.3641 7.55025C18.7546 7.15972 18.7546 6.52656 18.3641 6.13603C17.9736 5.74551 17.3404 5.74551 16.9499 6.13603L12.0001 11.0858L7.05037 6.13603C6.65984 5.74551 6.02668 5.74551 5.63616 6.13603C5.24563 6.52656 5.24563 7.15972 5.63616 7.55025L10.5859 12.5L5.63616 17.4497C5.24563 17.8403 5.24563 18.4734 5.63616 18.864C6.02668 19.2545 6.65984 19.2545 7.05037 18.864L12.0001 13.9142L16.9499 18.864C17.3404 19.2545 17.9736 19.2545 18.3641 18.864C18.7546 18.4734 18.7546 17.8403 18.3641 17.4497L13.4143 12.5L18.3641 7.55025Z" fill="#7D7D7D" />
  </svg>
}

export const ReportModal = (props: any) => {
  const { contextRef, loading, applicationId, userId, lang } = props
  const [reportLoading, setReportLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const reportReason = useSharedData('CONFIG')?.report_recruiter_reasons ?? []
  const [value, setValue] = useState()

  const dic = useSharedData('DICTIONARY') ?? {}
  const { comm = {}, reportModalInChat = {}, jobDetail } = dic || {}
  // jobDetail.feedbackModal.message
  const hideReportModal = () => {
    setOpen(false)
    setValue(null)
  }

  // reportRecruiter
  const context = {
    showReportModal() {
      setOpen(true)
    },
    hideReportModal
  }

  contextRef.current = assign(contextRef.current, context)
  const Component = Modal// isMobile ? Drawer : Modal

  return <Component
    width={500}
    open={open}
    footer={null}
    closable={false}
    rootClassName={styles.reportModal}
    onCancel={hideReportModal}>
    <div style={{ marginBottom: 24 }}>
      <div className={styles.header}>
        <span className={styles.title}>
          {/* Report */}
          {comm.report}
        </span>
        <span className={styles.closeIconWrapper} onClick={hideReportModal}>
          <CloseIcon />
        </span>
      </div>
      <div className={styles.description}>
        {/* Please help us understand this user's situation and explain the problem this user has. */}
        {reportModalInChat.description}
      </div>
    </div>

    <Radio.Group
      style={{ width: '100%' }}
      onChange={e => {
        setValue(e.target.value)
      }}
      value={value}
    >
      <Space direction="vertical" style={{ width: '100%', gap: 0 }}>
        {reportReason.map(v => {
          return <Radio key={v.id} value={v.id}
            className={styles.reportItem}
          >
            {v.title}
          </Radio>
        })}
      </Space>
    </Radio.Group>
    <div className={styles.reportModalFooter}>
      <Button onClick={hideReportModal}
        className={`${styles.btn} ${styles.cancelBtn}`}>
        {/* Cancel */}
        {comm.cancel}
      </Button>
      <Button
        className={`${styles.btn} ${styles.confirmBtn}`}
        type='primary'
        disabled={value === undefined || value === null}
        loading={reportLoading}
        onClick={() => {
          const recruiterId = contextRef.current?.getState()?.recruiter?.id;
          // debugger
          // console.log('log report')
          if (!value || !recruiterId) {
            return
          }
          setReportLoading(true)
          reportRecruiter(recruiterId, value).then(res => {
            if (res.status === 200) {
              let msg = jobDetail?.feedbackModal?.message || 'We have received your report about this job. Thanks for letting us know. Your feedback is important in helping us to keep the Bossjob community safe.'
              message.success(msg)
              setReportLoading(false)
              hideReportModal()
            }
          })
        }}>
        {/* Confirm */}
        {comm.confirm}
      </Button>
    </div>

  </Component>
}
