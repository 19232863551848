import axios from "../../helpers/axios"

export const update = applicationId => {
    return axios().get(`/job-applications/${applicationId}/jobseekers`)
}

export const updateChat = chatId => {
    return axios().get(`/job-applications/chats/${chatId}/jobseekers`)
}
export const updateChat2 = chatId => {
    return axios().get(`/job-applications/chats/${chatId}/jobseekers?im20=1`)
}