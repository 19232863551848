/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'
import { createContext, useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { IMManager, hooks, scripts } from 'imforbossjob'
import SendResumeModal from './sendResume'
import Interview from './interview'
import NotInterestModal from './notInterest'
import { getCookie } from '../helpers/cookies'
import ExchangeModal from './exchange'
import CommonPhrases from './commonPhrases'
import ViewJobModal from './viewJob'
import ExchangeConfirmModal from './exchange/confirm'
import NotificationContainer, { usePushNotification } from './notificationContainer'
import ExchangeDetailModal from './exchange/detail'
import interpreters from '../interpreters'
import { pushNotification } from '../interpreters/services/notification'
import OfferModal from './offer'
import { formatTemplateString } from '../helpers/formatter'
import { DefaultAvatar } from '../images'
import { RecruiterModal } from './RecruiterModal'
import { assign } from 'lodash-es'
import { publishSharedData, receiveNotification, postNotification } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { useParams } from 'next/navigation'
const { offerJobseeker: { getDataAndShowOfferMessageScript } } = scripts
const wssUrl = process.env.WSS_BOSSJOB_URL
export const IMContext = createContext<any>({})
const Provider = IMContext.Provider
const msgToNote = (message, state) => {
    const avatar = state?.recruiter?.avatar ? state?.recruiter?.avatar : DefaultAvatar
    if (message.type === 1) {
        return {
            id: message.amid,
            title: state?.recruiter?.full_name ?? 'New Message',
            content: message?.content?.text,
            link: `/chat/${message?.aChatId}`,
            avatar
        }
    } else if (message.type === 2) {
        return {
            id: message.amid,
            title: state?.recruiter?.full_name ?? 'New Message',
            content: '[Picture]',
            link: `/chat/${message?.aChatId}`,
            avatar
        }
    } else if (message.type === 19 && message.amid.indexOf('resume_request-recruiter_create') >= 0) {
        return {
            id: message.amid,
            title: state?.recruiter?.full_name ?? 'New Message',
            content: 'Boss has requested your resume',
            link: `/chat/${message?.aChatId}`,
            avatar
        }
    } else if (message.type === 19 && message.amid.indexOf('contact_exchange_request-create') >= 0) {
        return {
            id: message.amid,
            title: state?.recruiter?.full_name ?? 'New Message',
            content: 'Boss has requested to exchange mobile number with you',
            link: `/chat/${message?.aChatId}`,
            avatar
        }
    } else if (message.type === 19 && message.amid.indexOf('interview-create') >= 0) {
        return {
            id: message.amid,
            title: state?.recruiter?.full_name ?? 'New Message',
            content: 'Boss has sent you an interview invite',
            link: `/chat/${message?.aChatId}`,
            avatar
        }
    } else if (message.type === 19 && message.amid.indexOf('location_confirmation-create') >= 0) {
        return {
            id: message.amid,
            title: state?.recruiter?.full_name ?? 'New Message',
            content: 'Boss has shared the working location with you',
            link: `/chat/${message?.aChatId}`,
            avatar
        }
    } else if (message.type === 19 && message.amid.indexOf('interview-update_result') >= 0) {
        return {
            id: message.amid,
            title: state?.recruiter?.full_name ?? 'New Message',
            content: 'Boss has sent you the interview result',
            link: `/chat/${message?.aChatId}`,
            avatar
        }
    }
}


const IMProvider = ({ children }: any) => {
    const logout = () => {
        (IMManager as any)?.logout?.()
        // localStorage?.clear?.()
    }
    useEffect(() => receiveNotification('IM_LOGOUT', logout), [])
    const { chat_id, lang } = useParams()
    const config = useSharedData('CONFIG')
    const chatDictionary = useSharedData('DICTIONARY')?.chat ?? {}
    const userDetail = useSharedData('ME')
    const contextRef = useRef<any>()
    const [isMobileVerified, setIsMobileVerified] = useState(userDetail?.is_mobile_verified)
    const isMobileVerifiedRef = useRef(isMobileVerified)
    const [feed, setFeed] = useState<any>({})
    const applicationId = feed?.job_application?.id
    const imState = useMemo(() => {
        const app = feed?.info ?? {}
        const newData = {
            ...app?.job_application,
            initiated_role: app?.initiated_role,
            delete_status: app?.delete_status,
            chatStatus: app?.status,
            self_role: 'recruiter',
            contact_exchange_request: feed?.job_application?.contact_exchange_request
            // userId
        }
        return newData
    }, [feed])
    useEffect(() => {
        const subscription = (IMManager as any).currentFeedSubject.subscribe(feed => {
            if (feed) {
                setFeed(feed)
            }
        })
        return () => subscription.unsubscribe()
    }, [])
    useEffect(() => {
        if (typeof (userDetail?.is_mobile_verified) !== 'undefined') {
            setIsMobileVerified(userDetail.is_mobile_verified)
        }
    }, [userDetail])
    useEffect(() => {
        isMobileVerifiedRef.current = isMobileVerified
    }, [isMobileVerified])
    const langRef = useRef(lang)
    useEffect(() => {
        langRef.current = lang
    }, [lang])
    const translate = useCallback((key, ...args) => {
        if (!chatDictionary) {
            return key
        } else if (args.length === 0) {
            return chatDictionary?.[key]
        } else {
            return formatTemplateString(key, ...args)
        }
    }, [chatDictionary])
    useEffect(() => {
        (IMManager as any).setOnAvatarClick((recruiterId) => {
            recruiterId && setRecruiterModal({ show: true, uid: recruiterId })
        })
    }, [lang])
    const [chatId, setChatId] = useState(chat_id)
    useEffect(() => {
        if (location.pathname.includes('/chat')) {
            const path = +chatId ? chatId : 'list'
            history.replaceState(null, '', `${path}`)
        }
    }, [chatId])
    const userDetailRef = useRef(userDetail)
    const userId = useMemo(() => {
        return userDetail?.id || getCookie('user')?.id
    }, [userDetail])

    const [loading, setLoading] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [totalUnread, setTotalUnread] = useState(0)
    const [isChatPage, setIsChatPage] = useState(true)

    useEffect(() => {
        publishSharedData('CHAT_TOTAL_UNREAD', totalUnread)
    }, [totalUnread])
    useEffect(() => {
        const updateMobile = () => {
            setMobile(document.body.clientWidth < 576)
        }
        updateMobile()
        window.addEventListener("resize", updateMobile);

        return () => {
            window.removeEventListener("resize", updateMobile)
        }
    }, [])

    useEffect(() => {
        const chatRegex = /^(\/[a-z]{2}(-[a-z]+)?)?\/chat(\/list|\/\d+)?$/
        if (chatRegex.test(location.pathname)) {
            setIsChatPage(true)
        } else {
            setIsChatPage(false)
        }
    }, [])

    const [recruiterModal, setRecruiterModal] = useState({
        show: false,
        uid: 0
    })


    const chatIdRef = useRef(chatId)

    const [isUnreadOn, setUnreadOn] = useState(false)
    const [status, setStatus] = useState()
    const filterMode = useMemo(() => {
        return !(!isUnreadOn && !status)
    }, [isUnreadOn, status])

    const onNoteiticationClick = useCallback(note => {
        const newId = note?.id ? note?.id : null
        setChatId(newId)
        publishSharedData('CHAT_ID', newId)
        //
        if (!isChatPage) {
            postNotification('ROUTE_PUSH', `/${lang}/${note.link}`)
        }
    }, [isChatPage])
    const { postNote, props: notificationProps } = usePushNotification(onNoteiticationClick)
    const postNoteRef = useRef(postNote)
    useEffect(() => {
        postNoteRef.current = postNote
    }, [postNote])
    const sessionid = getCookie('sessionid')

    useEffect(() => {
        const subscription = (IMManager as any)?.getBadges?.()
        return () => subscription.unsubscribe()
    }, [])

    // 全局Notification
    useEffect(() => {
        const subscription = (IMManager as any)?.getNoticeMessage?.()
        return () => subscription?.unsubscribes?.()
    }, [])

    useEffect(() => {
        if (sessionid) {
            // eslint-disable-next-line no-inner-declarations
            function access() {

                if (wssUrl) {
                    const uuid = localStorage.getItem('chatUuid')
                    if (uuid) {
                        (IMManager as any).accessUser(uuid, sessionid, wssUrl, 'jobseeker')
                    } else {
                        fetch('/im/id-generate', {
                            method: 'get', // or 'POST'
                            credentials: 'include'
                        })
                            .then(response => response.json())
                            .then(response => {
                                localStorage.setItem('chatUuid', response.data);

                                (IMManager as any).accessUser(response.data, sessionid, wssUrl, 'jobseeker')
                            })
                            .catch(error => console.log({ error }))
                    }
                } else {
                    setTimeout(access, 200)
                }
            }
            access()
        }
    }, [sessionid])
    useEffect(() => {
        chatIdRef.current = chatId
    }, [chatId])
    useEffect(() => {
        userDetailRef.current = userDetail
    }, [userDetail])
    const context = {
        setLoading,
        hideModals() {
            contextRef.current?.closeSendResume?.()
            contextRef.current?.closeConfirm?.()
            contextRef.current?.closeAccept?.()
            contextRef.current?.closeDetail?.()
            contextRef.current?.closeNotInterest?.()
            contextRef.current?.closeExchange?.()
            contextRef.current?.closeExchangeConfirm?.()
            contextRef.current?.closeCancelDetail?.()
            contextRef.current?.closeCancel?.()
            contextRef.current?.closeIssue?.()
            contextRef.current?.closeEditCommonPhrasesList?.()
            contextRef.current?.closeCommonPhrases?.()
            contextRef.current?.closeEditOneCommonPhrases?.()
            contextRef.current?.closeCreateOneCommonPhrases?.()
            contextRef.current?.closeDeleteOneCommonPhrases?.()
            contextRef.current?.closeAttend?.()
            contextRef.current?.closeAskFailed?.()
            contextRef.current?.closeJobDetail?.()
            contextRef.current?.closeExchangeDetail?.()
            contextRef.current?.closeOfferDetail?.()
            contextRef.current?.closeOfferMessage?.()
            contextRef.current?.hideReportModal?.()
        },
        updateUser() {
        },
        handleError(e) {
            if (e?.response?.data?.code) {
                const message = translate('' + e?.response?.data?.code) || e?.response?.data?.message
                console.log({ e, message, translate })
                contextRef.current?.showToast?.('error', message)
                return
            }

            // const content = errorParser(e)
            const content = ''
            if (typeof content === 'string') {
                contextRef.current?.showToast?.('error', content)
            } else {
                contextRef.current?.showToast?.('error', e.toString())
            }
        },
        handleFinish(type) {
            if (type === 'verifySuccess') {
                setIsMobileVerified(true)
            }
        },
        isUserNumberValidate() {
            return isMobileVerifiedRef.current
        },
        getLang() {
            return langRef.current
        },
        getRouter() {
            return ({
                push: link => postNotification('ROUTE_PUSH', link)
            })
        },
        translate,
        getApplicationId() {
            return feed?.job_application?.id
        },
        getChatId() {
            return feed?.chat_id
        },
        getState() {
            return imState
        },
        changeChat(chatId) {
            console.log({ changeChat: chatId })
            const newId = +chatId ? chatId : null
            setChatId(chatId)
            publishSharedData('CHAT_ID', newId)
        },
        showToast(type, content) {
            postNotification('SYSTEM_NOTETIFICATION',
                {
                    open: true,
                    message: content,
                    severity: type
                }
            )
        },
        postPageNotification(message, state) {
            // 当type等于2并且在chat页面不需要提示
            if (message.type === 2 && isChatPage) {
                return
            }

            // 如果不是普通文本
            if (message.type === 2 && message.message_kind_id !== 0) {
                return
            }

            //
            if (message.type === 2 && ![0, 1, 3].includes(message.message_type_id)) {
                return
            }

            let notification = {}
            // Add message
            if (message.type === 2) {
                notification = {
                    id: message.chat_id,
                    title: message.sender_name,
                    content: message.push_view_text,
                    link: 'chat/' + message.chat_id,
                    avatar: message.sender_avatar
                }
            }
            // On line push message
            if (message.type === 10) {
                notification = {
                    id: message.aChatId,
                    title: message.title?.value,
                    content: message?.body?.value,
                    link: 'chat/' + message.aChatId,
                    avatar: message.image
                }
            }

            if (!message?.pageActive && message.isSharedWorker) {
                requestNotificationPermission(message)
                return
            }

            postNoteRef.current?.(notification)
        },
        postLocalNotification(message, state) {
            // 这是原来的提示逻辑现在已经不用了
            const msg: any = msgToNote(message, state)
            if (msg) {
                const params: any = {
                    im_amid: message?.amid,
                    im_achat_id: message?.aChatId,
                    im_title: JSON.stringify({
                        t: 't',
                        v: state?.recruiter?.full_name ?? 'New Message'
                    }),
                    auth_role: 'jobseeker',
                    im_sender_id: `"${userDetailRef.current?.id || getCookie('user')?.id}_j"`,
                    im_receive_ids: `["${userDetailRef.current?.id || getCookie('user')?.id}_j"]`,
                    im_type: '' + message.type,
                    im_body: JSON.stringify([
                        {
                            t: 't',
                            v: msg?.content
                        }
                    ])
                }
                pushNotification(params)
                    .then(result => console.log('pushNotifictionSuccess', result))
                    .catch(e => console.log('pushNotifictionError', e))
            }
        },
        updateTotalUnreadNumber(totalUnreadNumber) {
            setTotalUnread(totalUnreadNumber)
        },

        self_role: 'jobseeker'
    } as any

    contextRef.current = assign(contextRef.current, context)

    const interpreter = hooks.useInterpreter(interpreters, contextRef)
    const manager = IMManager as any
    manager.interpreter = interpreter;
    useEffect(() => {
        const receive = e => {
            const data = e.detail?.data ?? {}
            console.log({ receive: e })
            if (data?.landing_page === 'Offer dialog') {
                interpreter(getDataAndShowOfferMessageScript(data.application_id, data.offer_id))
            }
        }
        window.addEventListener('receiveImNotification', receive)
        return () => window.removeEventListener('receiveImNotification', receive)
    }, [])

    const requestNotificationPermission = (message = null) => {
        // Shared worker 如果有页面打开但是没有页面获取焦点，走系统通知
        if (!('Notification' in window)) {
            return;
        }

        let notify = null

        if (Notification.permission === 'default') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    //
                    if (message) {
                        notify = newSystemNotification(message)
                    }
                } else {
                    //
                }
            }).catch(error => {
                console.error('请求通知权限时发生错误:Notification', error);
            });
        } else if (Notification.permission === 'granted') {
            //
            if (message) {
                notify = newSystemNotification(message)
            }
        } else {
            //
        }

        if (notify) {
            notify.onclick = (event) => {
                const data = event.target?.data || {}
                const { chatId } = data || {}
                window.open(location.origin + "/chat/" + chatId);
                notify.close();
            }
        }
    }

    const newSystemNotification = (message) => {
        if (message.type === 2) {
            return new Notification(message.sender_name, {
                body: message.push_view_text,
                icon: message.sender_avatar,
                data: { chatId: message.chat_id },
            })
        }

        if (message.type === 10) {
            return new Notification(message.title?.value, {
                body: message?.body?.value,
                icon: message.image,
                data: { chatId: message.aChatId },
            })
        }

    }

    return <Provider
        key='im-provider'
        value={{
            ready: true,
            userDetail,
            userId,
            imState,
            applicationId,
            contextRef,
            loading,
            mobile,
            chatId,
            totalUnread,
            isUnreadOn,
            setUnreadOn,
            filterMode,
            interpreter,
            status,
            setStatus,
            postNote,
            translate,
            config
        }}>{userId ? <>
            <SendResumeModal
                loading={loading}
                contextRef={contextRef}
                data={imState.resume_request}
                applicationId={applicationId}
                lang={chatDictionary}
            />

            <Interview
                loading={loading}
                contextRef={contextRef}
                config={config}
                lang={lang}
            />
            <OfferModal
                loading={loading}
                contextRef={contextRef}
                lang={chatDictionary}
                applicationId={applicationId}
            />
            <ExchangeModal
                loading={loading}
                contextRef={contextRef}
                lang={lang}
                config={config}
                userDetail={userDetail}
                applicationId={applicationId}
            />
            <ExchangeConfirmModal
                loading={loading}
                contextRef={contextRef}
                lang={lang}
                applicationId={applicationId}
            />
            <ExchangeDetailModal
                loading={loading}
                contextRef={contextRef}
                lang={lang}
                applicationId={applicationId}
            />
            <NotInterestModal
                loading={loading}
                data={imState}
                applicationId={applicationId}
                lang={chatDictionary}
                contextRef={contextRef}
            />
            <ViewJobModal
                loading={loading}
                data={imState?.interview}
                lang={chatDictionary}
                applicationId={applicationId}
                contextRef={contextRef}
                config={config}
            />

            <CommonPhrases
                loading={loading}
                userId={userId}
                lang={lang}
                applicationId={applicationId}
                contextRef={contextRef}
            />
            <RecruiterModal
                lang={chatDictionary}
                uid={recruiterModal.uid}
                display={recruiterModal.show}
                onClose={(show: boolean) => { setRecruiterModal({ show, uid: 0 }) }} />
        </> : null}
        {children}
        <NotificationContainer {...notificationProps} />
    </Provider>
}

export default IMProvider
