import { useState } from "react";
import { assign } from "lodash-es";
import { notification } from "antd";
import { isMobile } from "react-device-detect";
import { useSharedData } from 'bossjob-remote/dist/hooks'

import { accept } from "../../../interpreters/services/interview";
import styles from "../notification.module.scss";
import { SuccessSvg } from "../Svg";

const useDetailInterview = (data, contextRef, timeId, onCancel) => {
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { successful, successFulTip } = dictionary?.interview_v2 || {}

  const [loading, setLoading] = useState(false);
  const { job_application_id, id, interview_times = [], status_id } = data || {};

  // 为了兼容
  const context = {
    async acceptInterview(paramId = undefined) {
      const payload = {
        interview_time_id: null
      };
      if (paramId || timeId) {
        payload.interview_time_id = paramId || timeId;
      }

      if (!paramId && !timeId) {
        const id = interview_times[0]?.id;
        payload.interview_time_id = id;
      }

      setLoading(true);
      return await accept(job_application_id, id, payload)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            if (contextRef.current && contextRef.current.updatePath) {
              contextRef.current.updatePath("interview", data);
            }

            if (!isMobile) {
              openNotification();
            }

            if (onCancel) {
              onCancel()
            }

            return Promise.resolve(response);
          }
        })
        .catch((error) => {
          console.error("Accept interview error:", error);
          return Promise.reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  contextRef.current = assign(contextRef.current, context);

  const openNotification = () => {
    notification.success({
      message: successful,
      description: successFulTip,
      className: styles.notify,
      icon: <SuccessSvg />,
      duration: 0,
    });
  };

  return {
    status_id,
    loading,
    onAccept: contextRef?.current?.acceptInterview
  };
};

export default useDetailInterview;
