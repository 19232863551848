'use client'
import { useRouter } from 'next/navigation'
import { Button } from 'app/components/MUIs'
import styles from '../index.module.scss'
import { LogoIcon } from 'components/Header/Common/NavLogo'
export default function Error(props: { error: any; reset: () => void }) {
  let { reset } = props
  const router = useRouter()
  return (
    <section className={styles.errorMain}>
      <div className={styles.errorMain_loadingLogo}>
        <LogoIcon />
        {/* <img src={BossjobLogo} title='Bossjob logo' alt='Bossjob logo' /> */}
      </div>
      <h2>Something went wrong!</h2>
      <Button
        variant='outlined'
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => {
            reset?.();
            window.location.reload()
          }
        }
        sx={{
          textTransform: 'capitalize',
          marginRight: '15px'
        }}
      >
        Try again
      </Button>
      <Button
        variant='contained'
        onClick={() => router.push('/')}
        sx={{
          textTransform: 'capitalize'
        }}
      >
        Go Home
      </Button>
    </section>
  )
}
