import { useSharedData } from 'bossjob-remote/dist/hooks'

const useInterviewStatusList = () => {
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { modal_pending, modal_pendingResults, modal_accepted, modal_rejected, modal_completed, modal_cancelled, modal_expired } = dictionary?.interview_v2 || {}

  const interview_status_list = [
    { id: 1, value: modal_pending, color: "#E58600", shadow: "#FFEACC", bg: "#FFFAF2" },
    { id: 2, value: modal_accepted, color: "#05A6A3", shadow: "#CDF4F4", bg: "#F8FFFF" },
    { id: 3, value: modal_rejected, color: "#DD4242", shadow: "#FFE3E3", bg: "#FFF4F4" },
    { id: 5, value: modal_completed, color: "#2A9F47", shadow: "#D6F5DEA9", bg: "#F2FFF5" },
    { id: 6, value: modal_cancelled, color: "#121212", shadow: "#E9E9E9", bg: "#F0F0F0" },
    { id: 7, value: modal_expired, color: "#121212", shadow: "#E9E9E9", bg: "#F1F1F1" },
    { id: 8, value: modal_pendingResults, color: "#2A9F47", shadow: "#D6F5DEA9", bg: "#F2FFF5" }
  ];

  return {
    interview_status_list
  }
}

export default useInterviewStatusList