import axios from '../../helpers/axios'
// TODO
export const accept = (applicationId, inviteInterviewId, params) => {
    return axios().put(`/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}/accept`, params);
};
export const cancel = (applicationId, inviteInterviewId, params) => {
    return axios().put(`/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}/cancel`, params);
};
export const decline = (applicationId, inviteInterviewId, params) => {
    return axios().put(`/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}/decline`, params);
};
export const checkIn = (applicationId, inviteInterviewId) => {
    return axios().put(`/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}/check-in`);
};
export const reportIssue = (applicationId, inviteInterviewId, params) => {
    return axios().post(`/job-applications/jobseekers/interviews/${inviteInterviewId}/report`, params);
};
export const attend = (applicationId, inviteInterviewId, params) => {
    return axios().put(
        `/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}/mark-attendance`,
        params
    );
};
export const askResult = (applicationId, inviteInterviewId) => {
    return axios().put(`/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}/request-result`);
};

export const detail = (applicationId, inviteInterviewId) => {
    return axios().get(`/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}`);
};

export const check = (applicationId, inviteInterviewId) => {
    return axios().post(`/job-applications/${applicationId}/jobseekers/interviews/${inviteInterviewId}/check-result`);
};
