import React from "react";
import styled from "@emotion/styled";
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { SunColorPokerFaceSvg, SunColorSadSvg, SunColorSmilingSvg } from "../Svg";

const VisibleResult = ({ statusId }) => {
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { modal_passed, modal_notSuitable, notAttended, modal_interviewResult } = dictionary?.interview_v2 || {}

  return (
    <MainStyled>
      <div className="title">{modal_interviewResult}</div>

      <div className="result-list">
        {statusId === 6 && (
          <div className={"result result-show"}>
            <SunColorSmilingSvg />
            <span>{modal_passed}</span>
          </div>
        )}

        {statusId === 4 && (
          <div className={"result result-show"}>
            <SunColorSadSvg />
            <span>{modal_notSuitable}</span>
          </div>
        )}

        {statusId === 13 && (
          <div className={"result result-show"}>
            <SunColorPokerFaceSvg />
            <span>{notAttended}</span>
          </div>
        )}
      </div>
    </MainStyled>
  );
};

const MainStyled = styled.div`
  margin-top: 20px;

  .title {
    color: #121212;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 143.75% */
    margin-bottom: 8px;
  }

  .result-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .result {
      display: flex;
      height: 50px;
      padding: 16px;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 10px;
      align-self: stretch;
      flex-wrap: wrap;
      width: 100%;
      border-radius: 4px;
      background: #f6f6f6;

      color: #121212;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .result-show {
      background: #fff2bc;
    }
  }

  .comment {
    margin-top: 16px;
    .title {
      color: #121212;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px; /* 143.75% */
    }

    .title + p {
      color: #121212;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .ant-btn-primary {
    width: 100%;
    margin: 28px 0;
  }
`;

export default VisibleResult;
