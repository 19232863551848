import axios from '../../helpers/axios'

// eslint-disable-next-line no-undef
export const confirm = (applicationId,confirmLocationId, messageId) => {
  return axios().put(`/job-applications/${applicationId}/jobseekers/working-locations/${confirmLocationId}/confirm`, {
    message_id: messageId
  })
}
// eslint-disable-next-line no-undef
export const decline = (applicationId,confirmLocationId, messageId) => {
  return axios().patch(`/job-applications/${applicationId}/jobseekers/working-locations/${confirmLocationId}/decline`, {
    message_id: messageId
  })
}
