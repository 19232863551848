import React from "react";
import { Button } from "antd";
import styled from "@emotion/styled";
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { isMobile } from "react-device-detect";

const Footer = ({
  status_id,
  color,
  value,
  shadow,
  handleOpenRejectModal,
  handleOpenCancelModal,
  onAccept,
  loading,
  inThreeHours,
}) => {
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { modal_accept, modal_reject, modal_cancelInterview } = dictionary?.interview_v2 || {}

  return (
    <MainStyled color={color} shadow={shadow} isMobile={isMobile} >
      <div className="status">
        <span></span>
        {value}
      </div>

      <div className="button-group">
        {status_id === 1 && <Button onClick={handleOpenRejectModal}>{modal_reject}</Button>}
        {status_id === 1 && (
          <Button type="primary" loading={loading} onClick={onAccept}>
            {modal_accept}
          </Button>
        )}

        {status_id === 2 && !inThreeHours && <Button onClick={handleOpenCancelModal}>{modal_cancelInterview}</Button>}
      </div>
    </MainStyled>
  );
};

type styledType = {
  shadow: string;
  isMobile: boolean;
};

const MainStyled = styled.div<styledType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    font-size: ${(props) => props.isMobile ? "16px" : "14px"};

    span {
      display: flex;
      width: 6px;
      height: 6px;
      border-radius: 22px;
      background: ${(props) => props.color ?? "#0fc6c2"};
      box-shadow: ${(props) => `0px 0px 0px 4px ${props.shadow}`};
      margin-right: 10px;
    }
  }

  .button-group {
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;

    .ant-btn.ant-btn-default {
      display: flex;
      height: 36px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      color: #121212;
    }

    .ant-btn.ant-btn-primary {
      display: flex;
      height: 36px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #2378e5;
      color: #fff;
    }
  }
`;

export default Footer;
