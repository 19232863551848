'use client'
import { useState, useEffect } from 'react'
import { Stack } from 'app/components/MUIs'
import ModalShare from 'components/ModalShare'
import ModalReportJob from 'components/ModalReportJob'
import { postReportRequest } from 'store/actions/reports/postReport'
import { initialState } from 'store/reducers/config/fetchConfig'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import styled from '@emotion/styled'

export type sharePropsType = {
  id: number
  job_url: string
  isLogin?: boolean
}

const SharedStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0 !important;

  span {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
`

const JobClient = (props: sharePropsType) => {
  const { isLogin } = props
  const [reportJobReasonList, setReportJobReasonList] = useState<Array<any>>(null)
  const config = useSharedData('CONFIG') ?? initialState.response
  useEffect(() => {
    setReportJobReasonList(config?.report_job_reasons ?? initialState.response.report_job_reasons)
  }, [config])
  const isPostingReport = useSharedData('POST_REPORT_FETCHING')
  const postReportResponse = useSharedData('POST_REPORT_RESPONSE')
  const [isShowSearch, setIsShowSearch] = useState<boolean>(false)
  const [isShowReportJob, setIsShowReportJob] = useState<boolean>(false)
  const handlePostReportJob = (reportJobData) => {
    const postReportJobPayload = {
      reportJobData,
    }
    postNotification('DISPATCH', postReportRequest(postReportJobPayload))
  }

  return (
    <>
      {reportJobReasonList?.length ? (
        <SharedStyled>
          <span onClick={() => setIsShowSearch(true)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='21'
              viewBox='0 0 20 21'
              fill='none'
            >
              <path
                d='M11.6667 3.8335H16.6667V8.8335'
                stroke='#515151'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M16.6666 12.5275V16.0557C16.6666 16.6694 16.1691 17.1668 15.5555 17.1668H4.44436C3.83071 17.1668 3.33325 16.6694 3.33325 16.0557V4.94461C3.33325 4.33096 3.83071 3.8335 4.44436 3.8335H7.7777'
                stroke='#515151'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M10.8333 9.66683L16.6666 3.8335'
                stroke='#515151'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>
          {isLogin && (
            <span onClick={() => setIsShowReportJob(true)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='21'
                viewBox='0 0 20 21'
                fill='none'
              >
                <path
                  d='M10 9.09442V12.2194'
                  stroke='#515151'
                  strokeWidth='1.36364'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8.92187 4.09441L2.04687 15.9694C1.93734 16.1591 1.87959 16.3743 1.8794 16.5933C1.8792 16.8124 1.93657 17.0276 2.04576 17.2175C2.15495 17.4074 2.31212 17.5653 2.50154 17.6753C2.69095 17.7853 2.90595 17.8436 3.12499 17.8444H16.875C17.094 17.8436 17.309 17.7853 17.4985 17.6753C17.6879 17.5653 17.845 17.4074 17.9542 17.2175C18.0634 17.0276 18.1208 16.8124 18.1206 16.5933C18.1204 16.3743 18.0626 16.1591 17.9531 15.9694L11.0781 4.09441C10.9694 3.90446 10.8125 3.7466 10.6231 3.63681C10.4338 3.52703 10.2188 3.46921 9.99999 3.46921C9.78114 3.46921 9.56617 3.52703 9.37685 3.63681C9.18753 3.7466 9.03057 3.90446 8.92187 4.09441V4.09441Z'
                  stroke='#515151'
                  strokeWidth='1.36364'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10 15.9694C10.5178 15.9694 10.9375 15.5497 10.9375 15.0319C10.9375 14.5142 10.5178 14.0944 10 14.0944C9.48223 14.0944 9.0625 14.5142 9.0625 15.0319C9.0625 15.5497 9.48223 15.9694 10 15.9694Z'
                  fill='#515151'
                />
              </svg>
            </span>
          )}
        </SharedStyled>
      ) : null}
      {
        isShowSearch && <ModalShare
          selectedJob={props}
          jobDetailUrl={props?.job_url}
          isShowModalShare={isShowSearch}
          handleShowModalShare={setIsShowSearch}
        />
      }

      {isShowReportJob && reportJobReasonList.length && (
        <ModalReportJob
          isShowReportJob={isShowReportJob}
          handleShowReportJob={setIsShowReportJob}
          reportJobReasonList={reportJobReasonList}
          selectedJobId={props?.['id']}
          handlePostReportJob={handlePostReportJob}
          isPostingReport={isPostingReport}
          postReportResponse={postReportResponse}
        />
      )}
    </>
  )
}

export default JobClient
