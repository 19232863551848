import axios from "../../helpers/axios" 

export const uploadUserResumeService = (resume) => {
    const URL = '/jobseekers/upload-resume'
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
  
    const formData = new FormData()
    formData.append('file', resume)
    formData.append('filename', resume.name)
    formData.append('parse_resume', true)
  
    return axios().post(URL, formData, { headers })
  }
