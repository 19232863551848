import { getValueById } from "../../helpers/config/getValueById";
import GoogleMeetingIcon from "./Image/Interview_google_meeting_.png";
import MicrosoftIcon from "./Image/Interview_microsoft_teams.png";
import OtherIcon from "./Image/Interview_other_icon.png";
import SkypeIcon from "./Image/Interview_skype.png";
import ViberIcon from "./Image/Interview_viber.png";
import ZoomIcon from "./Image/Interview_zoom.png";

export const interview_online_method_lists = [
  { id: 1, key: "google_meet", value: "Google Meet", icon: GoogleMeetingIcon?.src },
  { id: 2, key: "zoom", value: "Zoom", icon: ZoomIcon?.src },
  { id: 3, key: "microsoft_teams", value: "Microsoft Teams", icon: MicrosoftIcon?.src },
  { id: 4, key: "skype", value: "Skype", icon: SkypeIcon?.src },
  { id: 5, key: "viber", value: "Viber", icon: ViberIcon?.src },
  { id: 6, key: "other", value: "Other", icon: OtherIcon?.src }
];


export const isRemoteJob = (job: any = {}) => {
  return job?.work_arrangement?.id === 3
}

export const getJobLocation = (job, dictionary, configstore) => {
  const { worldwide, allRegions } = dictionary ?? {}
  const isRemote = isRemoteJob(job ?? {})
  const { world_region_lists } = configstore || {}
  const remoteCountries = getRemoteJobLocations({
    world_region_lists,
    remote_countries: job?.remote_countries || []
  })

  const remote_local = [
    ...(remoteCountries.displayState?.map?.(v => {
      return `${v.value}`
    }) ?? []),
    ...(remoteCountries.displayCountry?.map?.(v => {
      return `${v.value} `
    }) ?? [])
  ].join(', ')

  const value = isRemote
    ? job?.is_remote_worldwide
      ? worldwide
      : remote_local ?? 'Remote'
    : job?.is_all_location
      ? allRegions
      : job?.job_location_id
        ? getValueById(configstore, job?.job_location_id, 'location_id')
        : job?.job_location || ''

  if (isRemote && !value) {
    return 'Remote'
  }

  return value
}

export const getJobSalary = (job, dictionary) => {
  const { Unpaid } = dictionary ?? {}
  const {
    salary_type,
    salary_range_value,
    annually_salary_range_value,
    daily_salary_range_value,
    hourly_salary_range_value,
    is_un_paid = 0
  } = job || {}

  if (is_un_paid !== 0) {
    return Unpaid
  }

  if (salary_type?.id === 1) {
    return salary_range_value
  }

  if (salary_type?.id === 2) {
    return daily_salary_range_value
  }

  if (salary_type?.id === 3) {
    return hourly_salary_range_value
  }

  return annually_salary_range_value ?? ''
}

export const getRemoteJobLocations = ({ world_region_lists, remote_countries }) => {
  const [, countryMap, stateToCountryMap]: any = (() => {
    const covertCountry2Opt = (continent = []) => {
      let countryMap = {}
      let stateToCountryMap = {}
      const all = continent.map((v: any) => {
        let children = []
        if (v.countries) {
          let childIds = []

          children = v.countries.map(item => {
            countryMap[item.id] = v.id
            childIds.push(item.id)

            return { value: item.id, label: item.value || item.display_name }
          })

          stateToCountryMap[v.id] = childIds
        }

        return { value: v.id, label: v.value || v.display_name, children }
      })
      return [all, countryMap, stateToCountryMap]
    }
    const country = world_region_lists
    return [...covertCountry2Opt(country)]
  })()

  const remoteValue = (() => {
    let remote_country_ids = remote_countries.map(v => v.id)
    let newValue = {}

    const addToNewValue = (key, value) => {
      let arr = newValue[key] || []
      arr.push(value)

      newValue[key] = arr
    }

    let displayState = []
    let displayCountry = []

    remote_country_ids.forEach(v => {
      addToNewValue(countryMap[v], v)
    })

    Object.keys(newValue).forEach(key => {
      const state = world_region_lists.find(v => Number(v.id) === Number(key))
      if (!state) {
        console.error(`world_region_lists has no this state, the state id is ${key}`)
        return
      }
      if (newValue[key].length === stateToCountryMap?.[key]?.length) {
        // all countries in this state have been found, so should display the state on page
        displayState.push(state)
      } else {
        let selectedCountries = newValue[key]
          ; (state.countries || []).forEach(item => {
            if (selectedCountries.includes(item.id)) {
              displayCountry.push(item)
            }
          })
      }
    })

    return { displayState, displayCountry }
  })()

  return remoteValue
}