import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import Modal from "../Modal";
import { useRef, useState } from "react";
import { assign } from "lodash-es";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import styles from "./index.module.scss";
const NotInterestModal = (props) => {
  const [show, setShow] = useState(false);
  const { contextRef, loading, lang } = props;

  const applicationId = contextRef.current?.getApplicationId()

  const {
    JobCcopeIsNotWhat,
    theSalaryIsNotWithin,
    notInterested,
    workingLocationIsTooFar,
    cancel,
    send,
    pleaseShareUsWhyThisRole
  } = lang ?? {};
  const [reason, setReason] = useState("");
  const actionsRef = useRef({});
  const context = {
    showNotInterest(actions) {
      actionsRef.current = actions;
      setShow(true);
    },
    closeNotInterest() {
      setShow(false);
    }
  };
  const reasons = [JobCcopeIsNotWhat, theSalaryIsNotWithin, workingLocationIsTooFar];
  contextRef.current = assign(contextRef.current, context);
  return _jsxs(Modal, {
    showModal: show,
    handleModal: () => actionsRef.current.close?.(),
    headerTitle: notInterested,
    firstButtonText: cancel,
    secondButtonText: send,
    firstButtonIsClose: false,
    secondButtonIsClose: false,
    handleFirstButton: () => actionsRef.current.close?.(),
    handleSecondButton: () =>
      actionsRef.current.send?.({
        applicationId,
        params: { not_interested_reason: reason }
      }),
    isSecondButtonDisabled: !reason,
    isSecondButtonLoading: loading,
    isFirstButtonLoading: loading,
    children: [
      _jsx("p", { children: pleaseShareUsWhyThisRole }),
      _jsx("div", {
        children: _jsx(RadioGroup, {
          "aria-labelledby": "demo-radio-buttons-group-label",
          name: "radio-buttons-group",
          onChange: (e) => setReason(e.target.value),
          children: reasons.map((item, index) =>
            _jsx(
              FormControlLabel,
              {
                classes: { root: styles.item },
                checked: item === reason,
                value: item,
                control: _jsx(Radio, {}),
                label: _jsx("div", { className: styles.reason, children: item })
              },
              index + "~" + item
            )
          )
        })
      })
    ]
  });
};
export default NotInterestModal;
