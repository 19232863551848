import axios from '../../helpers/axios'
export const pushNotification = params => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    }
    const formData = new FormData()
    Object.keys(params).forEach(key => {
        const value = params[key]
        formData.set(key, value)
    })

    return axios().post(`/notification/offline-push/send`, formData, {
        headers: headers
    })
}

