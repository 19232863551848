'use client'
import { useState } from 'react'
import styled from '@emotion/styled'
import { getCookie } from 'helpers/cookies'

import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'

import { postSaveJobService } from 'store/services/jobs/postSaveJob'
import { deleteSaveJobService } from 'store/services/jobs/deleteSaveJob'
import Toast from 'app/components/Toast'

const SaveStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

type propsType = {
  is_saved: boolean
  jobId: number
  jobDetail: any
  headerLang: any
}
const HeartFilled = () => {
  return <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
  >
    <path
      d='M10 17.3965C10 17.3965 2.1875 13.0215 2.1875 7.70899C2.1875 6.76984 2.51289 5.8597 3.1083 5.13341C3.70371 4.40713 4.53236 3.90956 5.45328 3.72538C6.37419 3.5412 7.33047 3.68177 8.15943 4.12319C8.98838 4.5646 9.63879 5.27958 10 6.14649C10.3612 5.27958 11.0116 4.5646 11.8406 4.12319C12.6695 3.68177 13.6258 3.5412 14.5467 3.72538C15.4676 3.90956 16.2963 4.40713 16.8917 5.13341C17.4871 5.8597 17.8125 6.76984 17.8125 7.70899C17.8125 13.0215 10 17.3965 10 17.3965Z'
      fill='#2378E5'
      stroke='#2378E5'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
}

const HeartOutline = () => {
  return <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='16'
    viewBox='0 0 18 16'
    fill='none'
  >
    <path
      d='M9 14.8445C9 14.8445 1.1875 10.4695 1.1875 5.15699C1.1875 4.21784 1.51289 3.30769 2.1083 2.58141C2.70371 1.85512 3.53236 1.35756 4.45328 1.17338C5.37419 0.989197 6.33047 1.12977 7.15943 1.57118C7.98838 2.0126 8.63879 2.72758 9 3.59449C9.36121 2.72758 10.0116 2.0126 10.8406 1.57118C11.6695 1.12977 12.6258 0.989197 13.5467 1.17338C14.4676 1.35756 15.2963 1.85512 15.8917 2.58141C16.4871 3.30769 16.8125 4.21784 16.8125 5.15699C16.8125 10.4695 9 14.8445 9 14.8445Z'
      stroke='#515151'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
}

const Save = (props: propsType) => {
  const { jobId: id, is_saved, headerLang } = props
  const sessionid = getCookie('sessionid')
  const [isSave, setIsSave] = useState<boolean>(is_saved)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)

  const handleSaveJob = () => {
    setSaveLoading(true)

    postSaveJobService({
      job_id: id
    })
      .then(() => {
        setIsSave(true)
        Toast.success(headerLang.savedSuccess)
      })
      .catch(([error]) => {
        const { response: { data } } = error
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            message: data.message ? data.message : 'Save job fail',
            severity: 'error'
          })
        )
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  const handleUnSaveJob = () => {
    setSaveLoading(true)

    deleteSaveJobService(id)
      .then(() => {
        setIsSave(false)
        Toast.success(headerLang.unSaveSuccess)
      })
      .catch(([error]) => {
        const { response: { data } } = error
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            message: data.message ? data.message : 'UnSave job fail',
            severity: 'error'
          })
        )
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  return (
    <SaveStyled
      onClick={() => {
        if (!sessionid) {
          sessionStorage.setItem('redirectPage', window?.location?.pathname)
          postNotification('SHOW_LOGIN_MODAL')
          return
        } else if (!isSave) {
          handleSaveJob?.()
        } else {
          handleUnSaveJob?.()
        }
      }}
    >
      {isSave ? (
        <HeartFilled />
      ) : (
        <HeartOutline />
      )}
    </SaveStyled>
  )
}

export default Save
