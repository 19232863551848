/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
'use client'
import { useEffect, useState, useRef } from 'react'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import JobDetail from '../JobDetail/JobDetail'
import { fetchPopularJobs } from 'store/services/jobs/popularJobs'
import styles from 'app/index.module.scss'
import { SxProps, Theme } from '@mui/system'
import Link from 'components/Link'
import {
  fetchJobsForYouLogin
} from 'store/services/jobs/fetchJobsForYouLogin'
import { fetchJobsForYou } from 'store/services/jobs/fetchJobsForYou'
import { getValueById } from 'helpers/config/getValueById'
import { throttle } from 'lodash-es'
import { backTopBtn } from 'images/svg'
import { remoteJobsBanner } from 'images'
import { setCookie } from 'helpers/cookies'
import MuiTabs from '@mui/material/Tabs'
import ShowSalary from 'app/components/ShowSalary'
import { reportTrackerJobCounts } from 'components/ReportTracker/JobCountTracker'
import { jobCountSourceTracker } from 'components/ReportTracker/util'
import { fetchHotJobsListService, fetchGuestJobsListService } from 'store/services/jobs/fetchJobsList'

import vip_user_icon_url from './vip_user_icon.png'

import { addEventTag } from 'helpers/utilities'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { getSingaporeAddress } from 'helpers/getSingaporeAddress'
import { message } from 'antd'
import { getCountryId } from 'helpers/country'
import { getDeviceUuid } from 'helpers/guest'

const jobsForMeTags = [
  "hp_job_for_you_classification_click",
  "hp_job_for_you_pre_click",
  "hp_job_for_you_card_click",
  "hp_job_for_you_more_click"
]

const popularJobsTags = [
  "hp_popular_jobs_classification_click",
  "hp_popular_jobs_more_click"
]

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderBottom: '0.5px solid #BCBCBC'
        },
        scrollButtons: {
          borderRadius: '50px',
          background: '#fff',
          width: '24px',
          height: '24px'
        },
        indicator: {
          borderRadius: '5px',
          height: '3px'
        }
      }
    }
  }
})

interface StyledTabsProps {
  children?: React.ReactNode
  value: number | string
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void
  variant?: 'standard' | 'scrollable' | 'fullWidth'
  scrollButtons: boolean | 'auto'
  // langKey: string
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '&.MuiBox-root': {
    width: '100%'
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '87.3px',
    width: '100%',
    backgroundColor: '#136FD3',
    borderRadius: '5px'
  }
})

interface StyledTabProps {
  key: string
  label: string
  value: string
  sx: SxProps<Theme>
}

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ }) => ({
  '&.Mui-selected': {
    color: '#1D2129',
    fontWeight: '700'
  },
  '&:hover': {
    color: '#1D2129'
  }
}))

const tabTypeForLogin = {
  reco: '2',
  latest: '1'
}

const Tabs = ({ dictionary, location_id, langKey, isMobile, home = {}, initList, locationName, jobPreference, jobTitle, tabList, tabValue, sessionid, user, userDetail }: any) => {
  const config = useSharedData('CONFIG')
  const isLogin = !!sessionid
  const [showBtn, setShowBtn] = useState<boolean>(false)
  const showRemoteModal = false // !localStorage.getItem('showRemoteModal')
  const [isShowModal, setIsShowModal] = useState(showRemoteModal)

  useEffect(() => {
    window.addEventListener('scroll', useFn)

    return () => {
      window.removeEventListener('scroll', useFn)
    }
  }, [])

  const useFn = throttle(() => {
    getScrollTop()
  }, 500)

  const getScrollTop = () => {
    const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop
    if (scrollTopHeight > 960) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  const [value, setValue] = useState<string>(
    tabValue
  )
  const [list, setList] = useState<Array<any>>(initList)
  useEffect(() => {
    setList([...initList])
  }, [initList])
  // const [open, setOpen] = useState<boolean>(false)
  // const [message, setMessage] = useState<String>('')
  const [loading, setLoading] = useState<boolean>(false)

  // const user = getCookie('user')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    if (!isLogin) {
      addEventTag(popularJobsTags[0])
    }

    if (isLogin) {
      addEventTag(jobsForMeTags[0])
    }
  }
  const firstRender = useRef(true)
  const formattedDataList = list => list?.map?.(detail => {
    if (!dictionary || !config?.country_lists?.length) {
      return detail
    }

    const jobLocation = getSingaporeAddress(detail?.is_all_location, dictionary.home?.allRegions, getValueById(config, detail?.job_location_id, 'location_id'))
    const financingStage = getValueById(
      config,
      detail?.company_financing_stage_id ?? detail?.company?.financing_stage_id,
      'company_financing_stage_id'
    )
    const xpLvlValue = getValueById(config, detail?.xp_lvl_id ?? detail?.xp_lvl?.id, 'xp_lvl_id')
    const degreeValue = getValueById(config, detail?.degree_id ?? detail?.degree?.id, 'degree_id')
    const industry = getValueById(config, detail?.company_industry_id ?? detail?.company?.industry_id, 'industry_id')
    const company_url = detail?.company_url ?? detail?.company?.company_url
    const company_logo = detail?.company_logo ?? detail?.company?.logo_url
    const company_name = detail?.company_name ?? detail?.company?.name
    return { ...detail, company_url, company_logo, company_name, industry, jobLocation, financingStage, xpLvlValue, degreeValue }
  })
  // const formattedDataList = list => list?.map?.(detail => {
  //   if (!dictionary || !config?.country_lists?.length) {
  //     return detail
  //   }

  //   let jobLocation,
  //     financingStage,
  //     xpLvlValue,
  //     degreeValue,
  //     industry,
  //     company_url,
  //     company_logo,
  //     company_name

  //   if (tabValue !== 'Recommended') {
  //     jobLocation = getSingaporeAddress(detail?.is_all_location, dictionary?.home?.allRegions, getValueById(config, detail?.job_location_id, 'location_id'))
  //     financingStage = getValueById(
  //       config,
  //       detail?.company_financing_stage_id,
  //       'company_financing_stage_id'
  //     )
  //     xpLvlValue = getValueById(config, detail?.xp_lvl_id, 'xp_lvl_id')
  //     degreeValue = getValueById(config, detail?.degree_id, 'degree_id')
  //     industry = getValueById(config, detail?.company_industry_id, 'industry_id')
  //     company_url = detail?.company_url
  //     company_logo = detail?.company_logo
  //     company_name = detail?.company_name
  //   }
  //   else {
  //     jobLocation = getSingaporeAddress(detail?.is_all_location, dictionary?.home?.allRegions, getValueById(config, detail?.job_location_id, 'location_id'))
  //     financingStage = getValueById(
  //       config,
  //       detail?.company?.financing_stage_id,
  //       'company_financing_stage_id'
  //     )
  //     xpLvlValue = getValueById(config, detail?.xp_lvl?.id, 'xp_lvl_id')
  //     degreeValue = getValueById(config, detail?.degree?.id, 'degree_id')
  //     industry = getValueById(config, detail?.company?.industry_id, 'industry_id')
  //     company_url = detail?.company?.company_url
  //     company_logo = detail?.company?.logo_url
  //     company_name = detail?.company?.name
  //   }

  //   return {
  //     company_url,
  //     company_logo,
  //     company_name,
  //     industry,
  //     jobLocation,
  //     financingStage,
  //     xpLvlValue,
  //     degreeValue,
  //     ...detail
  //   }
  // })
  useEffect(() => {
    if (firstRender.current && value === tabValue) {
      firstRender.current = false
      return
    }
    let listPromise: any
    if (sessionid) {
      if (jobPreference) {
        listPromise = fetchJobsLogin()
      } else {
        listPromise = fetchJobsLoginNoPerferse()
      }
    } else {
      listPromise = handleFetchPopularJobs(value)
    }
    setLoading(true)
    listPromise.then(data => {
      setList(formattedDataList(data))
      const jobsIds = (data ?? []).map((item) => item?.id).join(',')
      reportTrackerJobCounts({ source: jobCountSourceTracker.home, job_ids: jobsIds })
    }).finally(() => {
      setLoading(false)
    })
  }, [value, location_id])

  const fetchJobsLogin = () => {
    return fetchJobsForYouLogin(
      {
        jobseekerPrefId: jobPreference?.id,
        page: 1,
        size: 6,
        sort: value
      }
    )
      .then((res) => {
        return res?.data?.data?.jobs
      })

  }

  const fetchJobsLoginNoPerferse = () => {
    return fetchJobsForYou({
      size: 6,
      sort: 1,
      source: 'web'
    })
      .then((res) => {
        return res?.data?.data.jobs
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleFetchPopularJobs = async (value) => {
    const uuid = await getDeviceUuid()
    if (value !== 'Recommended') {
      return fetchPopularJobs({
        size: 6,
        page: 1,
        job_location_ids: location_id,
        main_functions: value
      })
        .then(({ data: data }) => {
          return data?.data?.jobs ?? []
        })
        .catch((error) => {
          // setMessage(error?.message ?? 'Get job list error')
          // setOpen(true)
          message.error(error?.message ?? 'Get job list error')
        })
    }

    if (uuid || uuid !== 'undefined') {
      return fetchGuestJobsListService(getCountryId(), 1, 6, uuid).then(({ data: data }) => {
        return data?.data?.jobs ?? []
      })
        .catch((error) => {
          // setMessage(error?.message ?? 'Get job list error')
          // setOpen(true)
          message.error(error?.message ?? 'Get job list error')
        })
    }


  }


  const scrollTopFun = () => {
    document.documentElement.scrollIntoView({
      behavior: 'smooth'
    })
  }

  const closeModal = () => {
    setCookie('showRemoteModal', '1')
    // localStorage.setItem('showRemoteModal')
    setIsShowModal(false)
  }

  return (
    <div className={styles.popularJobsBox}>
      <h2 className={styles.jobTitle}>{sessionid ? home.jobCard.jobForYou : home.popularJobs}</h2>
      <div className={styles.webTab}>
        <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper', backgroundColor: 'transparent' }}>
          <TabContext value='1'>
            <ThemeProvider theme={theme}>
              <StyledTabs
                value={value}
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
                onChange={handleChange}
              // langKey={''}
              >
                {tabList.map((item) => (
                  <StyledTab
                    key={item.value}
                    label={item.tab}
                    value={item.value}
                    sx={{
                      fontSize: '16px',
                      textTransform: 'capitalize',
                      color: '#4E5969',
                      fontFamily: 'product sans',
                      letterSpacing: '1px',
                      width: 'auto',
                      padding: '12px 0',
                      marginRight: '51px',
                      fontWeight: '400'
                    }}
                  />
                ))}
              </StyledTabs>
              {sessionid ? (
                <div className={styles.preference}>
                  {jobPreference ? (
                    <div className={styles.preferenceBox}>
                      {user?.avatar ? (
                        !userDetail?.vip?.is_vip ? (
                          <img src={user?.avatar} />
                        ) : (
                          <div className={styles.vipAvatar}>
                            <img
                              src={vip_user_icon_url.src}
                              width='15'
                              height='6'
                              alt=''
                              style={{
                                position: 'absolute',
                                bottom: '-2px',
                                right: '-17px',
                                border: 0,
                                borderRadius: 0,
                                height: 'auto'
                              }}
                            />
                            <img src={user?.avatar} />
                          </div>
                        )
                      ) : null}
                      {home.jobPreference}:{' '}
                      <Link

                        href={`${langKey}/manage-profile?tab=job-preferences`}
                        className={styles.link}
                        onClick={() => {
                          addEventTag(jobsForMeTags[1])
                        }}
                      >
                        {locationName}{' '}
                        |{' '}
                        {jobTitle}{' '}
                        | <ShowSalary jobs={jobPreference} size={13} showTip={false} dictionary={dictionary} />
                      </Link>
                    </div>
                  ) : (
                    <p>
                      {home.improveRecommend}{' '}
                      <Link
                        href={`${langKey}/manage-profile?tab=job-preferences`}
                        className={styles.link2}
                      >
                        {' '}
                        {home.jobPrefer}
                      </Link>
                    </p>
                  )}
                </div>
              ) : null}
            </ThemeProvider>

            <div className={styles.tabContainer}>
              {!loading ? (
                list?.map((item) => (
                  <JobDetail
                    dictionary={dictionary}
                    langKey={langKey}
                    key={item.id}
                    detail={item}
                    tabValue={value}
                    prefJobTitle={jobPreference?.function_job_title_id}
                  />
                ))
              ) : (
                <Box sx={{ width: '100%' }}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((e) => (
                    <Skeleton
                      key={e}
                      width={'100%'}
                      height={20}
                      animation='wave'
                      sx={{ margin: '10px 0' }}
                    />
                  ))}
                </Box>
              )}

              <div className={styles.tabContainer_more} onClick={() => {
                if (!isLogin) {
                  addEventTag(popularJobsTags[2])
                }

                if (isLogin) {
                  addEventTag(jobsForMeTags[3])
                }
              }} >
                <Link className={styles.moreBtn} href={value !== 'Recommended' ? '/my-jobs' : '/personalized-job-picks'}>
                  {home.seeMoreBtn}
                </Link>
              </div>
            </div>
          </TabContext>
        </Box>
      </div>
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert severity='error'>{message}</Alert>
      </Snackbar> */}
      {
        showBtn && (
          <div className={styles.backBtn} onClick={() => scrollTopFun()}>
            {backTopBtn}
          </div>
        )
      }
      {
        isShowModal && (
          <div className={styles.pushRemoteModalBg}>
            <div className={styles.pushRemoteModal}>
              <div className={styles.thumb}>
                <span className={styles.closeIcon} onClick={() => closeModal()}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='15'
                    height='16'
                    viewBox='0 0 15 16'
                    fill='none'
                  >
                    <path
                      d='M9.28833 7.9822L14.6329 2.81678C14.8677 2.58981 14.9997 2.28197 14.9997 1.96099C14.9997 1.64001 14.8677 1.33218 14.6329 1.10521C14.3981 0.878242 14.0795 0.750732 13.7474 0.750732C13.4153 0.750732 13.0968 0.878242 12.862 1.10521L7.5174 6.27063L2.17283 1.10521C2.05655 0.992827 1.9185 0.90368 1.76658 0.842859C1.61465 0.782037 1.45181 0.750732 1.28737 0.750732C1.12292 0.750732 0.960086 0.782037 0.808158 0.842859C0.65623 0.90368 0.518184 0.992827 0.401904 1.10521C0.285623 1.21759 0.193383 1.35101 0.130453 1.49785C0.0675221 1.64468 0.0351323 1.80206 0.0351323 1.96099C0.0351323 2.11993 0.0675221 2.2773 0.130453 2.42414C0.193383 2.57097 0.285623 2.70439 0.401904 2.81678L5.75021 7.9822L0.405643 13.1476C0.281265 13.2579 0.181242 13.3913 0.111586 13.5399C0.0419305 13.6885 0.00408087 13.8492 0.000312151 14.0123C-0.00345657 14.1754 0.0269342 14.3375 0.0896564 14.4889C0.152379 14.6404 0.246137 14.778 0.365297 14.8935C0.484457 15.0091 0.626558 15.1001 0.783057 15.1612C0.939556 15.2223 1.10722 15.2522 1.27598 15.2491C1.44473 15.246 1.61109 15.2099 1.76505 15.143C1.91901 15.0762 2.0574 14.9799 2.17189 14.8601L7.51647 9.69467L12.862 14.8601C12.9783 14.9725 13.1164 15.0616 13.2684 15.1224C13.4204 15.1832 13.5833 15.2145 13.7478 15.2144C13.9123 15.2144 14.0751 15.183 14.2271 15.1222C14.379 15.0613 14.5171 14.9721 14.6334 14.8596C14.7496 14.7472 14.8419 14.6137 14.9048 14.4668C14.9677 14.3199 15 14.1625 15 14.0035C15 13.8446 14.9675 13.6872 14.9045 13.5403C14.8415 13.3934 14.7492 13.26 14.6329 13.1476L9.28833 7.9822Z'
                      fill='white'
                    />
                  </svg>
                </span>
                <img src={remoteJobsBanner} alt='' />
              </div>
              <p>{home.remoteJobIsOpen}</p>
              <div className={styles.button}>
                <Link

                  style={{ display: 'flex', flex: 1, marginLeft: isMobile ? 0 : '20px' }}
                  target='_blank'
                  href='/remote-jobs'
                  onClick={() => closeModal()}
                >
                  <Button
                    variant='outlined'
                    className={styles.mobileTab_btn}
                    sx={{
                      borderRadius: '8px',
                      background: '#2378E5',
                      color: '#fff',
                      fontSize: isMobile ? '17px' : '20px',
                      height: isMobile ? '48px' : '60px',
                      textTransform: 'capitalize',

                      ':hover': {
                        backgroundColor: 'rgba(35, 120, 229, .9)',
                        color: '#fff !important'
                      }
                    }}
                  >
                    {home.takALook}
                  </Button>
                </Link>
                <Button
                  variant='outlined'
                  className={styles.mobileTab_btn}
                  sx={{
                    borderRadius: '8px',
                    border: isMobile ? 'none' : '1px solid  #2378E5',
                    color: '#2378E5',
                    fontSize: isMobile ? '17px' : '20px',
                    height: isMobile ? '48px' : '60px',
                    textTransform: 'capitalize',
                    marginTop: isMobile ? '6px' : 0
                  }}
                  onClick={() => closeModal()}
                >
                  {home.seeLater}
                </Button>
              </div>
            </div>
          </div>
        )
      }
    </div >
  )
}

export default Tabs


