import axios from "axios";
import { getCountryId } from 'helpers/country'
const configuredAxios = () => {
    const instance = axios.create({
        baseURL: '/bossjob-api-server',
        headers: { 'country-id': getCountryId() }
    });
    return instance;
}
export default configuredAxios;
