/* eslint-disable no-useless-escape */
import { M } from 'imforbossjob'
import { infoAlertMessage } from './services/chat'
const urlPattern = /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,6}(?:\/[^\s]*)?/g

export default command =>
  command.cata({
    hasLink: payload =>
      M(() => {
        // const replacedText = text.replace(urlPattern, "aass1");
        return Promise.resolve(urlPattern.test(payload))
      }),
    replaceLink: payload => M(() => Promise.resolve(payload)),
    passContent: payload => M(() => Promise.resolve(payload)),
    requestMiddleMessage: () =>
      M(context => {
        const chatId = context.getChatId()
        return Promise.resolve().then(() => {
          setTimeout(() => {
            infoAlertMessage(chatId)
          }, 500);
        })
      })
  })
