import React from "react";
import { CloneSvg, VideoSvg } from "../Svg";
import { interview_online_method_lists } from "../config";
import { copyToClipboard } from "helpers/copyClipboard";
import { Button } from "antd";
import { useSharedData } from 'bossjob-remote/dist/hooks'

const MethodForOnline = ({ video_category, video_link, online_method_id = 1, status_id }) => {
  const dictionary = useSharedData('DICTIONARY') ?? {}
  const { modal_EnterTheInterview } = dictionary?.Interview_v2 || {}
  const { icon } = interview_online_method_lists.find((item) => item.id === online_method_id) ?? {};

  const handleEnterTheInterview = () => {
    if (video_link) {
      const url =
        video_link.startsWith("http://") || video_link.startsWith("https://") ? video_link : `https://${video_link}`;

      window.open(url, "_blank");
    }
  };

  return (
    <div className="field method">
      <div className="left">
        <VideoSvg width="16px" height="17px" />
        <div>
          <div className="name">
            <img src={icon} alt="icon" width={16} height={16} />
            <span>{video_category}</span>
          </div>
          <div className="link">{video_link}</div>
          {status_id === 2 && (
            <Button className="enter-interview" onClick={handleEnterTheInterview}>
              {modal_EnterTheInterview}
            </Button>
          )}
        </div>
      </div>
      <div className="copy" onClick={() => copyToClipboard(video_link)}>
        <CloneSvg />
      </div>
    </div>
  );
};

export default MethodForOnline;
