/* eslint-disable valid-jsdoc */
export * from 'helpers/country.base'
export * from 'helpers/country'
// import { configKey, getCookie } from './cookies'
// import { countryConfig, languages as initLanguages } from 'helpers/country.base'

// // Warning: the English language's value must be en-US,
// const countryCounfig = countryConfig

// export const languages = initLanguages
// export const serverContryCodeMap = {
//   'en-US': 'en',
//   'zh-CN': 'zh-CN',
//   'id-ID': 'id',
//   'ja-JP': 'ja',
//   'zh-TW': 'zh-TW',
//   'ms-MY': 'ms',
// }

// export const getLanguageCode = langKey => serverContryCodeMap[langKey] ?? langKey
// export const getLangKeyByCode = langCode => {
//   const langKey = Object.keys(serverContryCodeMap).find(key => serverContryCodeMap[key] === langCode)
//   return langKey ?? langCode
// }

// export const nations = countryCounfig.map(item => {
//   return {
//     value: item.key,
//     label: item.name,
//     id: item.id
//   }
// })
// export const getDefaultLocation = key => {

//   return countryCounfig.find(item => item.key === key)?.defaultLocation
// }

// const defaultNation = nations[0];

// export const defaultCountryKey = () => defaultNation.value
// export const defaultCountryId = () => defaultNation.id
// export const defaultCountry = () => defaultNation.label
// export const defaultCurrency = () => countryCounfig[0].currency

// export const defaultLanguage = () => languages[0].value
// export const defaultLanguageFullName = () => languages[0].label
// export const defaultLanguageId = () => languages[0].id


/**
 * TODO: the geoConfiguration's value is not always sames as URL
 */


// /**
//  * get Country key from Url
//  * @return  country
//  */
// export const getCountryKey = () => {
//   const path =
//     typeof window === 'undefined' ?
//       process.env.NEXT_PUBLIC_HOST_PATH :
//       window.location.hostname
//   // path maybe is  localhost
//   // don't use 127.0.0.1 as dev public path
//   const countryKey = path?.includes('.') && path?.split?.('.')?.pop()

//   return countryKey || defaultCountryKey()
// }



// export const getCountryKey = (useDefault = true) => {
//   const path = window.location.href
//   const country = countryCounfig.find(item => path?.includes?.(item.url))
//   if (country) {
//     return country.key
//   } else {
//     return useDefault ? defaultCountryKey() : null
//   }
//   // return (process.env.COUNTRY_KEY) || (process.env.HOST_PATH).split('.').pop()
// }

// /**
//  * get countryId by URL and supported countries
//  * @returns 
//  */
// export const getCountryId = () => {
//   const countryKey = getCountryKey()
//   return countryCounfig.find(item => item.key === countryKey)?.id ?? defaultCountryId()
// }

// /**
//  * get language code by URL or geoConfiguration in cookies
//  * @returns 
//  */
// export const getLang = () => {
//   let path = window.location.href
//   path = path?.split?.('//')[1]?.split?.('/')?.[1] // https://dev.bossjob.sg/en-US/...
//   return languages.map(item => item.value).includes(path) ? path : getCookie(configKey)?.split('_')?.[1] || defaultLanguage()
// }

// /**
//  * get language's full name from url or geoConfiguration in cookies
//  * @returns 
//  */
// export const getLanguage = () => {
//   const langCode = getLang()
//   const currentLang = languages.find((item) => item.value === langCode)

//   return currentLang?.label || defaultLanguageFullName()
// }

// /**
//  * get language's id from url or geoConfiguration in cookies
//  * @returns 
//  */
// export const getLanguageId = () => {
//   const langCode = getLang()
//   const currentLang = languages.find((item) => item.value === langCode)

//   return currentLang?.id || defaultLanguageId()
// }
// /**
//  * get country's full name from url, e.g. ph => Philippines
//  * @returns 
//  */
// export const getCountry = () => {
//   const countryKey = getCountryKey();
//   const country = nations.find(v => v.value === countryKey)

//   return country?.label || defaultCountry()
// }

// export const countryForCurrency = key => {

//   return countryCounfig.find(item => item.key === key)?.currency ?? defaultCurrency()
// }


// export const countryForPhoneCode = {
//   ph: '+63',
//   sg: '+65',
//   jp: '+81',
//   id: '+62'
// }