'use client'
import { createContext, useTransition } from "react";

export const TransitionContext = createContext<any>({})


const TransitionProvider = (props) => {

  const [loading, transitionStart] = useTransition()

  return <TransitionContext.Provider value={{ loading, transitionStart }}>
    {props.children}
  </TransitionContext.Provider>
}

export default TransitionProvider